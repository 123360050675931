import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Input, Toast } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../assets/scss/app-styles/extensions.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSyncAlt, faToggleOn, faToggleOff  } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class CoreManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCloudId: "",
            style: 'custom-spinner',
            CloudIdList: [],
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }



    componentDidMount() {
        this.setState({ style: 'custom-spinner' });
        const cloudId = JSON.parse(localStorage.getItem('creds')).cloud_id
        this.setState({ selectedCloudId: cloudId });
        this.loadCores(cloudId)
        
    }

    loadCores = (cloudId) => {
        const payLoad = {
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_URL}getCoresInfo`, payLoad, this.headers)

            .then(response => {
                this.createDataTable(response.data);

            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    toggleCore = (row) => {
        this.setState({ style: 'custom-spinner' });
        const payLoad = {
            cloud_id: this.state.selectedCloudId,
            region: row.region,
            toggleValue: !row.down,
        }
        axios.post(`${process.env.REACT_APP_API_URL}toggleCores`, payLoad, this.headers)
        .then(response => {

            this.loadCores(this.state.selectedCloudId)
        })
        .catch(err => {
            toast.error(err.message);
            this.setState({ style: 'custom-spinner-hide' });
        });
    }

    createDataTable = (dataFromApi) => {

        let columns = [

            {
                label: 'Region',
                field: 'region',
            },
            {
                label: 'Toggle 503 Core (Up/Down)',
                field: 'down',
                sort: 'disabled',
            },

        ];
        let dataModified = null;
        if (dataFromApi != null) {
            dataModified = dataFromApi.map((row, index) => {
                return ({
                    ...row,
                    down:
                        row.down?
                        <FontAwesomeIcon
                            icon={faToggleOn } className="icon-check"
                            style={{ fontSize: "20px", cursor: "pointer", marginLeft: '10px', color:"red" }}
                            title="Core is Down. Click to toggle Up!"
                            onClick={() => this.toggleCore(row)} />
                        : 
                        <FontAwesomeIcon
                            icon={faToggleOff } className="icon-check"
                            style={{ fontSize: "20px", cursor: "pointer", marginLeft: '10px', color:"green" }}
                            title="Core is Up. Click to toggle Down!"
                            onClick={() => this.toggleCore(row)} />
                });
            });

        }


        this.setState({ dataTable: { columns: columns, rows: dataModified } });

    }

    render() {


        return (
            <Fragment>
                <Breadcrumb parent="App" title="Core Management" />
                
                {
                    this.state.selectedCloudId !== "" && this.state.dataTable ?

                        <div className="container-fluid main-container">

                            <Card className="col-md-12 col-sm-12">
                                <CardHeader>
                                <div className="row">
                                    <div className="col-md-10 col-sm-12 ">
                                        <h5>Cores</h5>
                                    </div>
                                </div>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        pagesAmount={4} data={this.state.dataTable}
                                        searchTop searchBottom={false} />



                                </CardBody>

                            </Card>


                        </div>
                        :
                        null
                }
            </Fragment>
        );
    }

};

export default CoreManagement;