import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            fetching_method: "remote_api",
            active: "true"
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

	componentDidMount() {
		if(!this.props.location.state || JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: localStorage.getItem('user_id')
			};
			axios.post(`${process.env.REACT_APP_API_URL}subscriberDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				if (err.response){
                    toast.error(err.response.data);
                } else{
                    toast.error(err.message);
                }
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.accountcode || !this.state.data.cloud_id || !this.state.data.company || !this.state.data.phone_number) {
            toast.error('Fields with * are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id;
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateSubscriber`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

    allowUsersChangeHandler = (evt) => {
        let data = this.state.data;
        if(evt.target.getAttribute("data-action") === "activate"){
            data["allow_all_users"] = true;
        } else {
            data["allow_all_users"] = false;
        }
        this.setState({data});
    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/subscribers',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Subscribers" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Subscriber" : "Add Subscriber"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Account code
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="accountcode"
                                        onChange={this.inputChangeHandler} value={this.state.data.accountcode}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Cloud Id
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="cloud_id"
                                        onChange={this.inputChangeHandler} value={this.state.data.cloud_id}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Compnay Name
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="company"
                                    onChange={this.inputChangeHandler} value={this.state.data.company}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email
                                    </label>
                                    <input type="email" className="form-control" name="email"
                                        onChange={this.inputChangeHandler} value={this.state.data.email}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Phone Number
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="phone_number"
                                        onChange={this.inputChangeHandler} value={this.state.data.phone_number}/>
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label className="float-left">
                                        Fetching Method
                                    </label>
                                    <input type="text" className="form-control" name="fetching_method"
                                        onChange={this.inputChangeHandler} value={this.state.data.fetching_method} disabled/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label> Active  </label>
                                    <div className="active-radios" onChange={this.inputChangeHandler}>
                                        <input type="radio" value="true" name="active"
                                            checked={this.state.data.active}
                                            /> Yes
                                        <input className="margin-left"
                                            type="radio" value="false" name="active"
                                            checked={!this.state.data.active}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label> Allow All Users  </label>
                                    <div className="active-radios">
                                    {
                                        this.state.data.allow_all_users?
                                            <i className="fas fa-toggle-on data-table-icon mg-20" 
                                                onClick={this.allowUsersChangeHandler} 
                                                title="Disable Provisioning" 
                                                style={{color: "green"}}
                                                data-action="deactivate"/>
                                        :
                                            <i className="fas fa-toggle-off data-table-icon mg-20" 
                                                title="Enable Provisioning"
                                                onClick={this.allowUsersChangeHandler}  
                                                data-action="activate"/>
                                        
                                    }
                                    </div>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
