import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class CheckEmail extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            style: 'custom-spinner-hide',
            loading: true,
		    backToExtensions: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        //this.refreshData();
    }

    searchData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            email: this.state.email,
        };

        axios.post(`${process.env.REACT_APP_API_URL}checkEmailInNetsapien`, payLoad, this.headers)
        .then(response=>{
            
            if(response.data.length === 0){
                toast.error("No Record Found");
            }
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'First Name',
                field: 'first_name',
            },
            {
                label: 'Last Name',
                field: 'last_name',
            },
            {
                label: 'Subscriber Login',
                field: 'subscriber_login',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'Email',
                field: 'email',
            },
        ];

        this.setState({dataTable: {columns: columns, rows: dataFromApi}});
        this.setState({loading: false});
    }

    

    render() {

        let redirect = null;
        
        if(this.state.backToExtensions){
            redirect = <Redirect to = {{
                    pathname: "/app_management"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="App Management" title="Check Email" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 search-container">
                        <div className="row">
                            <div className="col-md-8 email-div">
                                <input type="text" className="form-control"
                                    placeholder="Enter email here"
                                    onChange={e => this.setState({email: e.target.value})} 
                                    value={this.state.email}/>
                            </div>

                            <div className="col-md-4"> 
                                <button onClick={this.searchData} type="button"  
                                className="btn btn-rounded-custom btn-primary table-btn" >Search </button>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-md-2"></div>
                    
                </div>

                <div className = "MainIndex">
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

                <div className="row main-container">
                    <div className="col-md-12">

                        <button type="button" onClick={evt => this.setState({backToExtensions: true})} 
                            className="button-custom btn shadow-2 btn-secondary btn-width">
                            CANCEL
                        </button>

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default CheckEmail;
