import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Input, Toast } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../assets/scss/app-styles/extensions.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class Region extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCloudId: "",
            style: 'custom-spinner',
            CloudIdList: [],
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }



    componentDidMount() {
        this.setState({ style: 'custom-spinner' });
        const cloudId = JSON.parse(localStorage.getItem('creds')).cloud_id
        if( cloudId === "CREXDEMO"){
            const payLoad = {
                user_id: JSON.parse(localStorage.getItem('creds')).id
            }
            axios.post(`${process.env.REACT_APP_API_URL}getCloudIds`, payLoad, this.headers)
                .then(response => {
    
                    let items = [];
                    response.data.map((row, index) => (
                        items.push({ cloudId: row.cloud_id })
                    ));
    
                    this.setState({ CloudIdList: items });
                    this.setState({ style: 'custom-spinner-hide' });
                })
                .catch(err => {
                    toast.error(err.message);
                    this.setState({ style: 'custom-spinner-hide' });
                });
        } else {
            this.setState({ selectedCloudId: cloudId });
            this.loadRegions(cloudId)
        }
        
    }


    cloudIdChangeHandler = (result) => {
        if (result.length === 0) {
            this.setState({ selectedCloudId: "" });
            return;
        }
        this.setState({ selectedCloudId: result[0].cloudId });
        this.setState({ style: 'custom-spinner' });
        this.loadRegions(result[0].cloudId)


    }
    loadRegions = (cloudId) => {
        const payLoad = {
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_URL}getRegionMap`, payLoad, this.headers)

            .then(response => {
                this.createDataTable(response.data);

            })
            .catch(err => {
                toast.error(err.message);
                this.setState({ style: 'custom-spinner-hide' });
            });
    }

    createDataTable = (dataFromApi) => {

        let columns = [

            {
                label: 'Region',
                field: 'region',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'sync',
                field: 'sync',
                sort: 'disabled',
            },

        ];
        let dataModified = null;
        if (dataFromApi != null) {
            dataModified = dataFromApi.map((row, index) => {
                return ({
                    ...row,
                    sync:
                        <FontAwesomeIcon
                        title='Resynch Region'
                            icon={faArrowsRotate} className="icon-check"
                            style={{ fontSize: "20px", cursor: "pointer", marginLeft: '10px' }}
                            onClick={() => this.syncSingle(row)} />
                });
            });

        }


        this.setState({ dataTable: { columns: columns, rows: dataModified } });

    }

    syncAll = () => {
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to execute global Sync? It will reprovision all the users of all domains!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ style: 'custom-spinner' });

                        const payLoad = {
                            cloud_id: this.state.selectedCloudId
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}globalsyncRegions`, payLoad, this.headers)
                        .then(response => {
                            toast.success(response.data);
                
                        })
                        .catch(err => {
                            toast.error(err.message);
                            this.setState({ style: 'custom-spinner-hide' });
                        });
                    }
                },
                {
                    label: 'No',
                        onClick: () => {
                        }
                }
            ]
            
        });
    }
    syncSingle = (row) => {
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to sync this region? It will also reprovision all the users in this domain!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ style: 'custom-spinner' });

                        const payLoad = {
                            cloud_id: this.state.selectedCloudId,
                            domain: row.domain
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}syncRegions`, payLoad, this.headers)
                        .then(response => {

                            toast.success("Region Synced");
                            this.loadRegions(this.state.selectedCloudId)
                        })
                        .catch(err => {
                            toast.error(err.message);
                            this.setState({ style: 'custom-spinner-hide' });
                        });
                    }
                },
                {
                    label: 'No',
                        onClick: () => {
                        }
                }
            ]
            
        });
        
    }


    render() {


        return (
            <Fragment>
                <Breadcrumb parent="App" title="Region" />
                {
                    JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO"?
                        <div className="container-fluid main-container">
                            <div className="col-md-1 col-sm-0"></div>
                            <Card className="col-md-10 col-sm-12">
                                <CardHeader>
                                    <h5>Choose Subscribers</h5>


                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div id="the-basics">
                                        <form className="theme-form">
                                            <div className="form-group">
                                                <i className="fa fa-search type-ahead-search-icon" />
                                                <Typeahead
                                                    id="basic-typeahead"
                                                    className="search-domains"
                                                    labelKey="cloudId"
                                                    multiple={false}
                                                    options={this.state.CloudIdList}
                                                    onChange={this.cloudIdChangeHandler}
                                                    placeholder="Choose a Subscriber..."
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="col-md-1 col-sm-0"></div>
                        </div>
                    : null
                }
                
                {
                    this.state.selectedCloudId !== "" && this.state.dataTable ?

                        <div className="container-fluid main-container">

                            <Card className="col-md-12 col-sm-12">
                                <CardHeader>
                                    <h5>Regions</h5>
                                    <div className="col-md-4 col-sm-12 domain-level-toggles check-all-provision">
                                        <FontAwesomeIcon
                                            title='Resynch All Domains'
                                            icon={faArrowsRotate} className="icon-check"
                                            style={{ fontSize: "20px", cursor: "pointer", marginTop: '10px' }}
                                            onClick={this.syncAll} />


                                    </div>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        pagesAmount={4} data={this.state.dataTable}
                                        searchTop searchBottom={false} />



                                </CardBody>

                            </Card>


                        </div>
                        :
                        null
                }
            </Fragment>
        );
    }

};

export default Region;