import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import ImageUploader from 'react-images-upload';

import '../../assets/scss/app-styles/users.scss'
import { ThisWeekTask } from '../../constant';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class WhiteLabel extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            icons: "white",
            vmail_transcription: "google"
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}
    populateItems = (data) => {

        let items = [];
        items.push(<option value={""}></option>);
        data.map((row)=>(
            items.push(<option value={row}>{row}</option>)
        ));

        return items;

    }

    populateDefaults(){
        axios.get(`${process.env.REACT_APP_API_URL}getDefaults`, this.headers)
            .then(response=>{

                let items = [];

                items = this.populateItems(response.data.email_encryption.Allowed);
                this.setState({emailEncryptionItems: items})

                items = this.populateItems(response.data.vmail_transcription.Allowed);
                this.setState({vmailTranscriptionItems: items})

            })
            .catch(err=>{
                toast.error(err.message);

            }
        );
    }

	componentDidMount() {
		if(!this.props.location.state || JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        this.populateDefaults();
        this.getSubscriberLevelProvisioning();

        let items = [];
        items.push(<option value="white">White</option>);
        items.push(<option value="black">Black</option>);
        items.push(<option value="#0A1A50">Dark Blue</option>);
        this.setState({iconsItems: items});

		this.setState({style: 'custom-spinner'});
        const payLoad = {
            subscriber_id: this.props.location.state.subscriberId,
            user_id: localStorage.getItem('user_id')
        };
        axios.post(`${process.env.REACT_APP_API_URL}whiteLabelDetails`, payLoad, this.headers)
        .then(response => {
            var data = this.state.data;
            data = response.data;
            this.setState({ data });
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            //toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.admin_base_url || !this.state.data.frontend_base_url || !this.state.data.email_host ||
            !this.state.data.email_port || !this.state.data.email_username || !this.state.data.email_password || 
            !this.state.data.email_from || !this.state.data.email_encryption|| !this.state.data.park_lower_limit || !this.state.data.vmail_transcription
            || !this.state.data.park_upper_limit || !this.state.data.netsapien_base_url) {
            toast.error('All fields are mandatory!');
            return;
		}

        if (!this.state.data.id && (!this.state.signin_logo || !this.state.dashboard_logo || 
            !this.state.custom_tab_background)){
            
            toast.error('All Logos/images are mandatory!');
            return;
        }

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
        let payLoad = new FormData();
        payLoad.append('admin_base_url', data.admin_base_url);
        payLoad.append('frontend_base_url', data.frontend_base_url);
        payLoad.append('email_host', data.email_host);
        payLoad.append('email_port', data.email_port);
        payLoad.append('email_username', data.email_username);
        payLoad.append('email_password', data.email_password);
        payLoad.append('email_from', data.email_from);
        payLoad.append('email_encryption', data.email_encryption);
        payLoad.append('park_lower_limit', data.park_lower_limit);
        payLoad.append('park_upper_limit', data.park_upper_limit);
        payLoad.append('park_lower_limit2', data.park_lower_limit2);
        payLoad.append('park_upper_limit2', data.park_upper_limit2);
        payLoad.append('netsapien_base_url', data.netsapien_base_url);
        payLoad.append('vmail_transcription', data.vmail_transcription);
        payLoad.append('icons', data.icons);

        if (this.state.signin_logo){
            payLoad.append('signin_logo', this.state.signin_logo);
        }
        if (this.state.dashboard_logo){
            payLoad.append('dashboard_logo', this.state.dashboard_logo);
        }
        if (this.state.custom_tab_background){
            payLoad.append('custom_tab_background', this.state.custom_tab_background);
        }

        payLoad.append('user_id',  JSON.parse(localStorage.getItem('creds')).id);
        payLoad.append('subscriber_id', this.props.location.state.subscriberId);
	
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateWhiteLabel`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

    onDropSignin = (pictureFiles, pictureDataURLs) => {
		this.setState({ signin_logo:  pictureFiles[0]});
    }
    onDropDashboard = (pictureFiles, pictureDataURLs) => {
        this.setState({ dashboard_logo:  pictureFiles[0]});
    }
    onDropCustomTab = (pictureFiles, pictureDataURLs) => {
        this.setState({ custom_tab_background:  pictureFiles[0]});
    }

    getSubscriberLevelProvisioning = () => {
        const payLoad = {
            subscriberId: this.props.location.state.subscriberId
        }
        axios.post(`${process.env.REACT_APP_API_URL}getProvisioningSubscriber`, payLoad, this.headers)
        .then(response=>{
            this.setState({provisioningSubscriberChecked: true});
            
        })
        .catch(err=>{
            //toast.error(err.message);
            this.setState({provisioningSubscriberChecked: false});
        });
    }

    provisioningSubscriberToggle = (evt) => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            subscriberId: this.props.location.state.subscriberId,
            cloudId: this.props.location.state.cloudId
        }
        let value = true;
        if(evt.target.getAttribute("data-action") === "add"){
            payLoad["check"] = true;
            confirmAlert({
                title: 'Alert!',
                message: 'This will enable ALL users in every domain, are you sure yo want to do this?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.toggleCallForSubscriber(value, payLoad);
                        }
                    },
                    {
                        label: 'No',
                            onClick: () => {
                            }
                    }
                ]
                
            });
        } else {
            payLoad["check"] = false;
            value = false;
            confirmAlert({
                title: 'Alert!',
                message: 'This will disable ALL users in every domain, are you sure yo want to do this?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.toggleCallForSubscriber(value, payLoad);

                        }
                    },
                    {
                        label: 'No',
                            onClick: () => {
                            }
                    }
                ]
                
            });
        }
        
        
    }

    toggleCallForSubscriber = (value, payLoad) => {
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}checkUncheckProvisioningSubscriber`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            if(value){
                this.setState({provisioningSubscriberChecked: true});
            } else {
                this.setState({provisioningSubscriberChecked: false});
            }
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
            
        });
    }

    reprovsionCompanyHandler = (evt) => {
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to reprovision all users for this company?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            cloud_id: this.props.location.state.cloudId
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}reprovisionByForce`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("All users in this company will be reprovisioned in few minutes!");
                            this.setState({style: 'custom-spinner-hide'});
                        })
                        .catch(err=>{
                            toast.error("Failed to reprovision");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/subscribers',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Subscribers" title="White Label" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>App Management White Labeling</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row">
                                <div className="col-md-6" style={{textAlign: "center"}}> 
                                    <label style={{marginRight: "10px"}}>Allow all users/all domains</label><br/>
                                    {
                                        this.state.provisioningSubscriberChecked?
                                        <i className="fas fa-toggle-on data-table-icon" 
                                            onClick={this.provisioningSubscriberToggle} 
                                            title="Disable Subscriber Level Provisioning"
                                            style={{color: "green"}}
                                            data-action="remove"/>
                                    :
                                        <i className="fas fa-toggle-off data-table-icon" 
                                            title="Enable Subscriber Level Provisioning"
                                            onClick={this.provisioningSubscriberToggle}
                                            data-action="add"/>
                                    }
                            
                                </div>
                                <div className="col-md-6" style={{textAlign: "center"}}> 
                                    <label style={{marginRight: "10px"}}>Reprovision all users for your company</label><br/>
                                    <i className="fa fa-refresh data-table-icon mg-20"
                                        onClick={this.reprovsionCompanyHandler}/>
                            
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>   
                <div className="container-fluid main-container">
                    

                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.state.data.id? "White Label Edit" : "White Label Add"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Admin Portal URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="admin_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.admin_base_url}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Frontend URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="frontend_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.frontend_base_url}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        SMTP Email Host
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="email_host"
                                    onChange={this.inputChangeHandler} value={this.state.data.email_host}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        SMTP Email Port
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_port"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_port}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email Username
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_username"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_username}/>
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label className="float-left">
                                        Email Password
                                    </label> <span> * </span>
                                    <input type="password" className="form-control" name="email_password"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_password}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email From Address
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email_from"
                                        onChange={this.inputChangeHandler} value={this.state.data.email_from}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email Encryption
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="email_encryption" 
                                        value={this.state.data.email_encryption}>
                                        {this.state.emailEncryptionItems}
                                    </select>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Call Parking Lower Limit
                                    </label> <span> * </span>
                                    <input type="number" className="form-control" name="park_lower_limit"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_lower_limit}/>
                                </div>
                                <div className="col-md-6">
                                <label className="float-left">
                                        Call Parking Upper Limit
                                    </label> <span> * </span>
                                    <input type="number" className="form-control" name="park_upper_limit"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_upper_limit}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Call Parking Lower Limit 2
                                    </label>
                                    <input type="number" className="form-control" name="park_lower_limit2"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_lower_limit2}/>
                                </div>
                                <div className="col-md-6">
                                <label className="float-left">
                                        Call Parking Upper Limit 2
                                    </label>
                                    <input type="number" className="form-control" name="park_upper_limit2"
                                        onChange={this.inputChangeHandler} value={this.state.data.park_upper_limit2}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">
                                    <label className="float-left">
                                        Dynamic Core URL (Region.domain/ns-api - region is dynamically pulled from NS UI)
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="netsapien_base_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.netsapien_base_url}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Icons
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="icons" 
                                        value={this.state.data.icons}>
                                        {this.state.iconsItems}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Vmail Transcription
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="vmail_transcription" 
                                        value={this.state.data.vmail_transcription}>
                                        {this.state.vmailTranscriptionItems}
                                    </select>
                                </div>
                            </div>

                            <div className="row form-group">
                                <label className="col-md-12">
                                        Signin Logo*
                                    </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.signin_logo?
                                        <img className="align-self-center pull-right img-100 blur-up lazyloaded" 
                                        src={process.env.REACT_APP_API_URL + this.state.data.signin_logo } 
                                        alt="header-user" style={{background: "black"}}/>
                                        :null
                                    }
                                </div>
                                <div className="col-md-6">
                                    
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropSignin}
                                        imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={( 5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>
                                
                            </div>


                            <div className="row form-group">
                                <label className="col-md-12">
                                Dashboard Logo*
                                    </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.dashboard_logo?
                                        <img className="align-self-center pull-right img-100 blur-up lazyloaded" 
                                        src={process.env.REACT_APP_API_URL + this.state.data.dashboard_logo } 
                                        alt="header-user" style={{background: "black"}}/>
                                        :null
                                    }
                                </div>
                                <div className="col-md-6">
                                    
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropDashboard}
                                        imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={( 5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>
                                
                            </div>


                            <div className="row form-group">
                                <label className="col-md-12">
                                    Custom Tab Background*
                                    </label>
                                <div className="col-md-6">
                                    {
                                        this.state.data.custom_tab_background?
                                        <img className="align-self-center pull-right img-100 blur-up lazyloaded" 
                                            src={process.env.REACT_APP_API_URL + this.state.data.custom_tab_background } 
                                            alt="header-user" style={{background: "black"}}/>
                                        :null
                                    }
                                </div>
                                <div className="col-md-6">
                                    
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=""
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropCustomTab}
                                        imgExtension={[".jpg",".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={( 5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default WhiteLabel;
