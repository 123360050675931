import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'
import moment from 'moment';


class BlockedUsers extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            userIdSelected: null,
            style: 'custom-spinner',
            loading: true,
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        this.refreshData();
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}getBlcokedUsers`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'User Login',
                field: 'login_user',
            },
            {
                label: 'Created Time',
                field: 'created_time',
            },
            {
                label: 'Un-Block User',
                field: 'unblock',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                row.created_time = moment(row.created_time).format('YYYY-MM-DD HH:mm:ss');
                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, unblock:  <div className="edit-column">
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.unBlockHandler} data-id={row.id} data-user={row.login_user} ></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    unBlockHandler = (event) =>{
        confirmAlert({
            title: 'Are you sure you want to unblock this user?',
            message: '',
            buttons: [
                {
                    label: 'Unblock',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            login_user : event.target.getAttribute("data-user"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}unBlockUser`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("User unblocked successfully");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    editHandler = (event) =>{
        this.setState({userIdSelected: event.target.getAttribute("data-id")})
        // will call the details compnent but with data id selected here as a prop

    }

    render() {

        let redirect = null;

        if(this.state.backToIndex){
            redirect = <Redirect to = {{
                    pathname: "/index"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="Blocked Subscribers" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className = "MainIndex">
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default BlockedUsers;
