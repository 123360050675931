import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Typeahead } from 'react-bootstrap-typeahead';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class Summary extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            profileIdSelected: null,
            style: 'custom-spinner',
            loading: true,
            domainList: [],
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        this.refreshData();
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        };

        axios.post(`${process.env.REACT_APP_API_URL}profileSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Name',
                field: 'profilename',
            },
            {
                label: 'Company',
                field: 'cloud_id',
            },
            {
                label: 'Transport',
                field: 'transport',
            },
            {
                label: 'Outbound Proxy Enabled',
                field: 'outboundproxyenabled',
            },
            {
                label: 'Outbound Proxy Host',
                field: 'outboundproxyhost',
            },
            {
                label: 'Expiry',
                field: 'expires',
            },
            {
                label: 'Get Domain Level',
                field: 'domainsCp',
            },
            {
                label: 'Edit - Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                if(row.outboundproxyenabled){
                    row.outboundproxyenabled = "Yes"
                }
                else {
                    row.outboundproxyenabled = "No"
                }

                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-edit data-table-icon"
                    onClick={this.editPrimaryHandler} data-id={row.id}></i> -
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deletePrimaryHandler} data-id={row.id}></i>
                </div>,
                domainsCp: <div className="edit-column">
                    <FontAwesomeIcon 
                        icon={faServer} className="data-table-icon"
                        onClick={e => this.getDomains(row.cloud_id)}/>
                </div>
                });
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    getDomains = (cloudId) => {
        this.getDomainsForCloudId(cloudId);
        this.setState({dataTableExtensions: null})
        const payLoad = {
            cloud_id: cloudId
        };

        axios.post(`${process.env.REACT_APP_API_URL}profileForDomainsSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTableForDomains(response.data);
        })
        .catch(err=>{
            toast.error(err.message);
        });
    }

    createDataTableForDomains = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Name',
                field: 'profilename',
            },
            {
                label: 'Company',
                field: 'cloud_id',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'Transport',
                field: 'transport',
            },
            {
                label: 'Outbound Proxy Enabled',
                field: 'outboundproxyenabled',
            },
            {
                label: 'Outbound Proxy Host',
                field: 'outboundproxyhost',
            },
            {
                label: 'Expiry',
                field: 'expires',
            },
            {
                label: 'Fetch For Extensions',
                field: 'extensionsCp',
            },
            {
                label: 'Edit - Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                if(row.outboundproxyenabled){
                    row.outboundproxyenabled = "Yes"
                }
                else {
                    row.outboundproxyenabled = "No"
                }

                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-edit data-table-icon"
                    onClick={this.editHandler} data-id={row.id}></i> -
                <i className="far fa-trash-alt data-table-icon"
                    onClick={e => this.deleteHandler(e, "domains")} data-id={row.id}></i>
                </div>,
                extensionsCp: <div className="edit-column">
                    <FontAwesomeIcon 
                        icon={faPhone} className="data-table-icon"
                        onClick={e => this.getExtensions(row.cloud_id, row.domain)}/>
                </div>
                });
            });

        }
        this.setState({dataTableDomains: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    getExtensions = (cloudId, domain) => {
        const payLoad = {
            cloud_id: cloudId,
            domain: domain,
        };

        axios.post(`${process.env.REACT_APP_API_URL}profileForExtensionsSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTableForExtensions(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTableForExtensions = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Name',
                field: 'profilename',
            },
            {
                label: 'Company',
                field: 'cloud_id',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'Extension',
                field: 'extension',
            },
            {
                label: 'Transport',
                field: 'transport',
            },
            {
                label: 'Outbound Proxy Enabled',
                field: 'outboundproxyenabled',
            },
            {
                label: 'Outbound Proxy Host',
                field: 'outboundproxyhost',
            },
            {
                label: 'Expiry',
                field: 'expires',
            },
            {
                label: 'Edit - Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                if(row.outboundproxyenabled){
                    row.outboundproxyenabled = "Yes"
                }
                else {
                    row.outboundproxyenabled = "No"
                }

                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-edit data-table-icon"
                    onClick={this.editHandler} data-id={row.id}></i> -
                <i className="far fa-trash-alt data-table-icon"
                    onClick={e => this.deleteHandler(e, "extensions")} data-id={row.id}></i>
                </div>
                });
            });

        }
        this.setState({dataTableExtensions: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    getDomainsForCloudId = (cloudId) => {
        this.setState({cloudIdSelected: cloudId});
        this.setState({style: 'custom-spinner'});
        let payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        }
        if(cloudId){
            payLoad["cloud_id"] = cloudId
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}getAllDomains`, payLoad, this.headers)
        .then(response=>{

            const domains = response.data
            let items = [];
            
            domains.map((row)=>(
                items.push({domain: row.domain})
            ));
            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }


    deletePrimaryHandler = (event) =>{
        if(JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
            toast.warn("Only Crexendo Engineering Support can delete this record");
            return;
        }
        confirmAlert({
            title: 'Are you sure you want to delete this client profile detail?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteProfile`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    deleteHandler = (event, from) =>{
        confirmAlert({
            title: 'Are you sure you want to delete this client profile detail?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteProfile`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            if(from === "domains"){
                                this.getDomains(this.state.cloudIdSelected);
                            } else {
                                this.getExtensions(this.state.cloudIdSelected, this.state.domainSelected);
                            }
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    domainChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({dataTableExtensions: null});
            return;
        }
        this.setState({domainSelected: result[0].domain})
        this.getExtensions(this.state.cloudIdSelected, result[0].domain);
    }

    editPrimaryHandler = (event) =>{
        if(JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
            toast.warn("Only Crexendo Engineering Support can edit this record");
            return;
        }
        this.setState({profileIdSelected: event.target.getAttribute("data-id")})
        // will call the details compnent but with data id selected here as a prop

    }
    editHandler = (event) =>{
        this.setState({profileIdSelected: event.target.getAttribute("data-id")})
        // will call the details compnent but with data id selected here as a prop

    }

    addNewHandler = (evt) => {
        evt.preventDefault();

        this.setState({newRecordClicked: true});

    }

    render() {

        let redirect = null;
        if(this.state.newRecordClicked){
            this.setState({newRecordClicked: false});
            redirect = <Redirect to = {{
                pathname: "/client_profiles/details",
                state: { id: null}
            }} />;

        }
        
        if(this.state.profileIdSelected){
            this.setState({profileIdSelected: null});
            redirect = <Redirect to = {{
                pathname: "/client_profiles/details",
                state: { id: this.state.profileIdSelected}
            }} />;
        }

            if(this.state.backToIndex){
                redirect = <Redirect to = {{
                        pathname: "/index"
                }} />;
            }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="Client Profiles" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className = "MainIndex" style={{marginBottom: "100px"}}>
                    <div className="row" >
                        <div className="col-md-12">
                            <button style={{float:"right"}} onClick={this.addNewHandler} type="button"  className="btn btn-rounded-custom btn-primary table-btn" >Add New </button>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">   
                            <h3 className='table-header'>Primary Client Profile (Editable only by Crexendo Engineering Support)</h3>
                        </div>
                        
                    </div>
                    <div className="users-table">
                        <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                        pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />
                    </div>

                    {
                        this.state.dataTableDomains && (
                            <div>
                                <div className="row">
                                    <div className="col-md-12">   
                                        <h3 className='table-header'>Domain Level Client Profiles</h3>
                                    </div>
                                    
                                </div>
                                <div className="users-table">
                                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                                    pagesAmount={4} data={this.state.dataTableDomains} searchTop  searchBottom={false} />
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.domainList.length > 0 && (
                            <div>
                                <div className="row">
                                    <div className="col-md-12">   
                                        <h3 className='table-header'>Extension Level Client Profiles</h3>
                                    </div>
                                    
                                </div>
                                <div className="form-group">
                                    <i className="fa fa-search type-ahead-search-icon"/>
                                    <Typeahead
                                        id="basic-typeahead"
                                        className="search-domains"
                                        labelKey="domain"
                                        multiple={false}
                                        options={this.state.domainList}
                                        onChange={this.domainChangeHandler}
                                        placeholder="Choose a domain..."
                                    />
                                    
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.dataTableExtensions && (
                        
                            <div className="users-table">
                                <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                                pagesAmount={4} data={this.state.dataTableExtensions} searchTop  searchBottom={false} />
                            </div>
                        )
                    }
                    
                </div>

            </Fragment>

        );

    }

}

export default Summary;
