import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import './notes.css'; // Import your custom CSS file for styling
import CKEditors from "react-ckeditor-component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import '../../assets/scss/app-styles/users.scss'

const ReleaseNotes = () => {
    const [notes, setNotes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [editDetails, setEditDetails] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        releaseNotesSummary();
    }, []);
    const headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    const handleEditClick = () => {
        setIsModalOpen(true);
    };


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDateChange = (date) => {
        console.log('date', date)
        setSelectedDate(date);
    };


    const handleDescriptionChange = (evt) => {
        setDescription(evt.editor.getData());
    }

    const DeleteNotes = (id) => {
        const payLoad = {
            id: id
        };
        axios.post(`${process.env.REACT_APP_API_URL}deleteReleaseNotes`, payLoad, headers)
            .then(response => {
                toast.success('Note Deleted Successfully');

                releaseNotesSummary();
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    console.log(err.message);
                }
            });
    }



    const releaseNotesSummary = () => {
        const payLoad = {};
        axios.post(`${process.env.REACT_APP_API_URL}releaseNotesSummary`, payLoad, headers)
            .then(response => {
                setNotes(response.data);
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    console.log(err.message);
                }
            });
    }

    const saveAndUpdate = () => {

        if (!title || !description) {
            toast.info('Title and description are required');
            return;
        }
        // Check if a date is selected
        if (!selectedDate) {
            toast.info('Please select a date');
            return;
        }


        const date = selectedDate.toISOString();


        let payload = {};

        if (editDetails) {
            payload = {
                title: title,
                description: description,
                created_time: date,
                id: editDetails.id
            };
        } else {
            payload = {
                title: title,
                description: description,
                created_time: date,
            };
        }
        axios.post(`${process.env.REACT_APP_API_URL}releaseNotesCreateUpdate`, payload, headers)
            .then(response => {
                toast.success('Note Created Successfully');
                setTitle('')
                setDescription('')
                setSelectedDate('')
                setIsModalOpen(false)
                releaseNotesSummary();
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    console.log(err.message);
                }
            });
    }

    return (
        <Fragment>
            <Breadcrumb parent="App" title="Release Notes" />

            {JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO" ? <div className="d-flex justify-content-end">
                <button onClick={() => {
                    handleEditClick()
                    setEditDetails(null)
                    setTitle('')
                    setDescription('')
                    setSelectedDate('')
                }} type="button" className="btn btn-rounded-custom btn-primary table-btn align-self-end" >Add New </button>
            </div> : null}

            {notes ? (
                <div className="step-indicator-container">
                    {notes
                        .sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
                        .map((chapter, index) => (
                            <div
                                key={index}
                                className={`step `}
                            >
                                {index !== 0 && <div className="line" />}
                                <div className="row">
                                    <div className="step-number col-6 ">{new Date(chapter.created_time).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                                    {JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO" ? <div className="edit-column col-6">
                                        <i className="far fa-edit data-table-icon" onClick={() => {
                                            handleEditClick()
                                            setEditDetails(chapter)
                                            setTitle(chapter.title)
                                            setDescription(chapter.description)
                                            const dateObject = new Date(chapter.created_time);
                                            setSelectedDate(dateObject);

                                        }}></i>-  -
                                        <i onClick={() => DeleteNotes(chapter.id)} className="far fa-trash-alt mr-2 data-table-icon"></i>
                                    </div> : null}
                                </div>
                                <div
                                    style={{
                                        marginLeft: '45px',
                                        borderLeft: '1px dotted #24a0ed',
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: '20px',
                                            padding: '20px',
                                            backgroundColor: '#D3D3D3b3',
                                            width: '600px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                                fontSize: '16px',
                                            }}
                                            className="step-description"
                                        >
                                            {chapter.title}
                                        </div>
                                        <hr />
                                        <div
                                            style={{
                                                wordWrap: 'break-word',
                                                maxWidth: '400px',
                                                color: 'black',
                                            }}
                                            className="step-description"
                                        >
                                            {ReactHtmlParser(chapter.description)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            ) : null}
            <Modal isOpen={isModalOpen} >
                <ModalHeader >{"Notes"}</ModalHeader>
                <ModalBody>
                    <label className="col-form-label" htmlFor="message-text">{"Select Date:"}</label>
                    <DatePicker selected={selectedDate} onChange={handleDateChange} />
                    <label className="col-form-label" htmlFor="message-text">{"Title:"}</label>
                    <input type="text" className="form-control" name="title" value={title}
                        onChange={handleTitleChange} />
                    <label className="col-form-label" htmlFor="message-text">{"Description:"}</label>
                    <CKEditors
                        activeclassName="p10"
                        data={description}
                        content={description}
                        events={{
                            "change": handleDescriptionChange
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={saveAndUpdate} >{"Save"}</Button>
                    <Button onClick={() => { setIsModalOpen(false) }} color="secondary">{"Cancel"}</Button>
                </ModalFooter>
            </Modal>

        </Fragment>
    );
}

export default ReleaseNotes;