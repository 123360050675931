
import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader, Input, Toast } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { Typeahead } from 'react-bootstrap-typeahead';
import { MDBDataTableV5 } from 'mdbreact';
import {Redirect} from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import QRModal from './qrModal'
import CallingNumberModal from './callingNumberModal';
import SharedVM from './sharedVm'
import '../../assets/scss/app-styles/extensions.scss'
class Extensions extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            goToCheckEmailPage: false,
            selectedDomain: "",
            allUsersCheckedList: "",
            usersAdded: "",
            usersRemoved: "",
            style: 'custom-spinner',
            cc: '',
            body: '',
            to: '',
            subject: '',
            qrModal: false,
            callingNumberModal: false,
            sharedVmModal: false,
            showTemplateSave: false,
            domainList: [],
            availableCallingNumbers: [],
            selectedCallingPhoneNumber: [],
            usersList: [],
            sortBy: "first_name",
            globalEmailList: "",
            globalCheckMark: JSON.stringify(new Array()),
            subscriberList : [
                // {user:'120'},
                // {user:'130'},
                // {user:'140'},
            ],
            sharedVmList : [
                // {user:'120'},
                // {user:'130'},
                // {user:'140'},
            ]
            
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }
    sortItems = [
        <option value="first_name"> First Name</option>,
        <option value="user"> Extension</option>
    ]
    sortChangeHandler = (evt) => {
        this.setState({sortBy: evt.target.value});
        const subscribersList = (this.state.mySubList).sort(this.GetSortOrder(evt.target.value));
        this.setState({mySubList: subscribersList});
        this.refreshUsers(subscribersList, this.state.allUsersCheckedList, this.state.globalCheckMark);
        this.setState({searchValue: ""});
        this.setState({filteredListOfUsers: null});
        
    }
    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }
    componentDidMount () {
        
        this.setState({style: 'custom-spinner'});
        const payLoad = new FormData();
        payLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=domain&action=read&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, payLoad)
        .then(response=>{
            let items = [];
            response.data.map((row, index)=>{
                if(this.props.location.state && this.props.location.state.selectedDomain1 && row.domain === this.props.location.state.selectedDomain1[0].domain){
                    this.domainChangeHandler(this.props.location.state.selectedDomain1);
                    this.setState({previouslySelectedDomain:[row.domain]});
                }
                items.push({domain: row.domain.toString()})
            });
            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    getDevice=()=>{
        const payLoad = {
            domain : this.state.selectedDomain,
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}getDeviceLimit`, payLoad,this.headers)
        .then(response=>{
         
            this.setState({['device_limit']: response.data});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    
    limitChangeHandler =()=>{
        if(JSON.parse(localStorage.getItem('creds')).user_type === "simple user") {
            return;
        }
        if(this.state.device_limit< 1 || this.state.device_limit >20){
            toast.info("Please Set the between 1 to 20");
            return false;
        }
        const payLoad = {
            domain : this.state.selectedDomain,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            device_limit:this.state.device_limit
        }
        
        
        axios.post(`${process.env.REACT_APP_API_URL}SetDeviceLimit`, payLoad,this.headers)
        .then(response=>{
            toast.info(response.data);
            this.getDevice();
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    userChangeHandler = (evt) => {
        let allUsersCheckedList = this.state.allUsersCheckedList;
        if(evt.target.getAttribute("data-name") === "off"){
            //add
            if(allUsersCheckedList === ""){
                
                allUsersCheckedList = "&" + evt.target.getAttribute("data-value") +"&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else{
                allUsersCheckedList += ",&" + evt.target.getAttribute("data-value") +"&";
            }
        }
        else{
            //remove
            const list =  (allUsersCheckedList).split(",");
            allUsersCheckedList = "";
            for(let i=0; i<list.length; i++){
                // if found the value ignore
                if(list[i] !== "&" + evt.target.getAttribute("data-value") +"&"){
                    if(allUsersCheckedList === ""){
                
                        allUsersCheckedList = list[i];
                    }
                    else{
                        allUsersCheckedList += "," + list[i];
                    }
                }
                
            }
        }
        this.setState({allUsersCheckedList: allUsersCheckedList});
        if(this.state.filteredListOfUsers){
            this.refreshUsers(this.state.filteredListOfUsers, allUsersCheckedList, this.state.globalCheckMark);
        } else {
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark);
        }
    }
    
    domainChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedDomain: ""});
            this.setState({usersList: []});
            return;
        }
        this.checkQuickDial(result[0].domain)
        this.setState({selectedPreDomain: result});
        this.setState({selectedDomain: result[0].domain});
        this.setState({style: 'custom-spinner'});
        let payLoad = new FormData();
        payLoad.append('domain', result[0].domain);
        payLoad.append('fields', "user,domain,dir,email,first_name,last_name,subscriber_login,scope");
        payLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=s&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, payLoad)
        .then(response=>{
            //const allowedScopes = ["system user", "Office Manager", "Elite", "Professional", "Advanced", "Call Center Agent", "Site Manager", "Super User"]
            // let subscribersList = (response.data).filter((el) => {
            //     return el.scope !== "";
            // });
            
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            let subscribersList = (response.data).sort(this.GetSortOrder("first_name"));
            this.setState({sortBy: "first_name"});
            //const subscribersList = response.data;
            this.setState({mySubList: subscribersList});
            this.setState({globalCheckMark: JSON.stringify(new Array())});
            this.getCheckedInfo();
            this.checkIfDomainChecked();
            this.setState({checkedAll: false});
            this.getDevice();
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    checkIfDomainChecked = () => {
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}checkProvisioningDomain`, payLoad, this.headers)
        .then(response=>{
            this.setState({provisioningDomainChecked: true});
            
        })
        .catch(err=>{
            //toast.error(err.message);
            this.setState({provisioningDomainChecked: false});
        });
    }
    saveHandler = () => {
       // const allUsersList = this.state.allUsersCheckedList.replaceAll("&","");
        const checkedList = this.state.allUsersCheckedList;
        let allUsersList = [];
        const subscribersList = this.state.mySubList;
        for(let i=0; i<subscribersList.length; i++){
            const info = {
                extension: subscribersList[i].user,
                allowed: checkedList.includes("&"+subscribersList[i].user+"&")
            }
            console.log(info)
            allUsersList.push(info);
        }
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            username: JSON.parse(localStorage.getItem('creds')).username,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            list: allUsersList,
        }
        axios.post(`${process.env.REACT_APP_API_URL}checkExtensions`, payLoad, this.headers)
        .then(response=>{
            toast.success("Successfully Saved!");
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
        
    }
    getCheckedInfo = () => {
        this.setState({style: 'custom-spinner'});
        
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}getExtensionsChecked`, payLoad, this.headers)
        .then(response=>{
            const extensionsChecked= response.data;
            let allUsersCheckedList = "";
            for(let i=0; i< extensionsChecked.length; i++){
                if(allUsersCheckedList === ""){
                    allUsersCheckedList = "&" + extensionsChecked[i].extension +"&";
                }else{
                    allUsersCheckedList += ",&"+extensionsChecked[i].extension +"&";
                }
            }
            this.setState({allUsersCheckedList: allUsersCheckedList});
            this.setState({originalAllUsersCheckedList: extensionsChecked})
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({allUsersCheckedList: ""});
            this.refreshUsers(this.state.mySubList, "", this.state.globalCheckMark);
            this.setState({style: 'custom-spinner-hide'});
        });

        
    }
    provisioningDomainToggle = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type === "simple user") {
            return;
        }
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : this.state.selectedDomain,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            username: JSON.parse(localStorage.getItem('creds')).username,
        }
        let value = true;
        if(evt.target.getAttribute("data-action") === "add"){
            payLoad["check"] = true;
            confirmAlert({
                title: 'Alert!',
                message: 'This will enable All users within this domain, are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.toggleCallForDomain(value, payLoad);
                        }
                    },
                    {
                        label: 'No',
                            onClick: () => {
                            }
                    }
                ]
                
            });
        } else {
            payLoad["check"] = false;

            value = false;
            confirmAlert({
                title: 'Alert!',
                message: 'This will disable All users within this domain, are you sure?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.toggleCallForDomain(value, payLoad);
                        }
                    },
                    {
                        label: 'No',
                            onClick: () => {
                            }
                    }
                ]
                
            });
           
        }
        
        
    }
    toggleCallForDomain = (value, payLoad) => {
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}checkUncheckProvisioningDomain`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            if(value){
                this.setState({provisioningDomainChecked: true});
            } else {
                this.setState({provisioningDomainChecked: false});
            }
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
            
        });
    }
    checkAll = (evt) => {
        if(evt.target.getAttribute("data-action") === "add"){
            let allUsersCheckedList = this.state.allUsersCheckedList;
            for(let i=0; i<(this.state.mySubList).length; i++){
                if(allUsersCheckedList === ""){
                    allUsersCheckedList = "&" + this.state.mySubList[i].subscriber_login.split("@")[0] +"&";
                }else{
                    allUsersCheckedList += ",&"+this.state.mySubList[i].subscriber_login.split("@")[0] +"&";
                }
            }
            this.setState({allUsersCheckedList:allUsersCheckedList});
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark);
            this.setState({checkedAll: true});
        }
        else{
            const data = this.state.originalAllUsersCheckedList;
            let allUsersCheckedList = "";
            if(data){
                for(let i=0; i< data.length; i++){
                    if(allUsersCheckedList === ""){
                        allUsersCheckedList = "&" + data[i].extension +"&";
                    }else{
                        allUsersCheckedList += ",&"+data[i].extension +"&";
                    }
                }
            }
            this.setState({allUsersCheckedList:allUsersCheckedList});
            this.setState({searchValue: ""});
            this.setState({filteredListOfUsers: null});
            this.refreshUsers(this.state.mySubList, allUsersCheckedList, this.state.globalCheckMark);
            this.setState({checkedAll: false});
        }
        toast.info("Please click Save button below to Save changes")
    }
    
    onChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    sendEmailHandler = (evt) => {
        const subscribersList = this.state.mySubList;
        if(!this.state.to || !this.state.subject || !this.state.body){
            toast.error("To, Subject and Body are mandatory fields to send an email!");
            return;
        }
        
        for(let i=0; i< (subscribersList).length; i++){
            let email = subscribersList[i].email.split(";")[0];
            let body =  this.state.body;
            if(email !== "" &&  (this.state.to).includes(email)){
                body = body.replace("FIRST_NAME_HERE", subscribersList[i].first_name);
                body = body.replace("LAST_NAME_HERE", subscribersList[i].last_name);
                const webUrl = window.location.origin.replaceAll("admin", "web");
                const link = `<a href="${webUrl}/getQR?username=${subscribersList[i].subscriber_login}&cloud_id=${JSON.parse(localStorage.getItem("creds")).cloud_id}">Click Here</a>`
                body = body.replace("LINK_HERE", link);
                if(email === '120@Saevolgo.ca'){
                    email = 'daniyal@saevolgo.ca'
                }
                const payLoad = {
                    recepient: email,
                    cc: this.state.cc,
                    subject: this.state.subject,
                    body: body,
                    user_id: JSON.parse(localStorage.getItem('creds')).id,
                };
        
                axios.post(`${process.env.REACT_APP_API_URL}emailLink`, payLoad, this.headers)
                .then(response=>{
                    console.log("Sucessfully sent email to "+ subscribersList[i].subscriber_login.split("@")[0]);
                    this.setState({qrModal: false});
                })
                .catch(err=>{
                    toast.error("Unable to send an email to "+ subscribersList[i].subscriber_login.split("@")[0]);
                });
            }
        }
    }
    toggleHandler = () => {
        this.setState({qrModal: !this.state.qrModal});
    };
    sharedVmToggleHandler = () => {
        this.setState({sharedVmModal: !this.state.sharedVmModal});
    };
    qrHandler = async (event) => {
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});
        this.setState({to: event.target.getAttribute("data-email")});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }
    editHandler = (evt) => {
        this.setState({idSelected: evt.target.getAttribute("data-id")});
        this.setState({loginSelected: evt.target.getAttribute("data-email")});
        
    }
    qrCodeHandler = async (event) => {
        //csc:username:password@CODE
        this.setState({cc: ''});
        this.setState({body: ''});
        this.setState({subject: ''});
        this.setState({template_name: ''});
        this.setState({showTemplateSave: false});
        let emailList = this.state.globalEmailList;
        if (emailList === ""){
            toast.error('No user with email selected! Please select users first, from checkboxes at the left.');
            return;
        }
        this.setState({to: emailList});
        await this.getEmailTemplates();
        this.setState({qrModal: true});
    }
    templateSelectedHandler = (evt) => {
        const data = this.state.templatesData;
        this.setState({body: data[evt.target.getAttribute("data-id")]["body"]});
        this.setState({template_name: data[evt.target.getAttribute("data-id")]["name"]});
        this.setState({subject: data[evt.target.getAttribute("data-id")]["subject"]});
        this.setState({showTemplateSave: true});
        this.setState({templateSelected: true});
    }
    deleteTemplate = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to delete email template");
            return
        }
        confirmAlert({
            title: 'Are you sure you want to delete this email template?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id: evt.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}deleteTemplate`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Successfully Deleted Template");
                            this.getEmailTemplates();
                            this.setState({style: 'custom-spinner-hide'});
                            this.setState({body: ''});
                            this.setState({subject: ''});
                            this.setState({template_name: ''});
                            this.setState({showTemplateSave: false});
                            this.setState({templateSelected: false});
                        })
                        .catch(err=>{
                            toast.error("Failed to delete email template");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    getEmailTemplates = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id : JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}getTemplates`, payLoad, this.headers)
        .then(response=>{
            const data = response.data;
            let items = [];
            let templatesData = {};
            data.map((row, index)=>{
                templatesData[row.id] = {
                    body: row.body,
                    name: row.name,
                    subject: row.subject
                }
                return (items.push(
                    <div key={"templates"+index} className="template-container">
                        <label className="name-label" onClick={this.templateSelectedHandler} data-id={row.id}>{row.name}</label>
                        <div onClick={this.deleteTemplate} data-id={row.id}> <i data-id={row.id} className="fa fa-times-circle"></i> </div>
                    </div>)
            )});
            this.setState({templatesData: templatesData});
            this.setState({templatesList: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({templatesData: null});
            this.setState({templatesList: []});
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    editorOnChangeHandler = (evt) => {
        this.setState({body: evt.editor.getData()})
    }
    toggleTemplateSave = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to add email template");
            return
        }
        if(!this.state.showTemplateSave){
            this.setState({body: ''});
            this.setState({subject: ''});
            this.setState({template_name: ''});
            this.setState({templateSelected: false});
        }
        this.setState({showTemplateSave: !this.state.showTemplateSave})
    }
    saveTemplateHandler = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type !== "admin"){
            toast.error("You are not allowed to update email template");
            return
        }
        this.setState({style: 'custom-spinner'});
        if(!this.state.template_name || !this.state.body){
            toast.error("Template name and body are mandatory");
            this.setState({style: 'custom-spinner-hide'});
            return;
        }
        const payLoad = {
            name: this.state.template_name,
            body: this.state.body,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }
        if(this.state.subject !== ''){
            payLoad["subject"] = this.state.subject;
        }
        axios.post(`${process.env.REACT_APP_API_URL}templateCreateUpdate`, payLoad, this.headers)
        .then(response=>{
            toast.success("Successfully saved email template");
            this.setState({templateSelected: true});
            this.getEmailTemplates();
        })
        .catch(err=>{
            toast.error("Failed to save email template");
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    searchChangeHandler = (evt) => {
        this.setState({searchValue: evt.target.value});
        let users = this.state.mySubList;
        users = users.filter((value, index) => {
            const firstName = value.first_name.toLowerCase();
            const lastName = value.last_name.toLowerCase();
            const user = value.user.toLowerCase();
            const searchResult =  evt.target.value.toLowerCase();
            if(user.includes(searchResult) || firstName.includes(searchResult) || lastName.includes(searchResult)){
                return true;
            } else {
                return false;
            }
        });
        this.setState({filteredListOfUsers: users});
        this.refreshUsers(users, this.state.allUsersCheckedList, this.state.globalCheckMark);
    }
    checkIfEveryElementExisits = (data, globalCheckMark) => {
        for (let i=0; i< data.length; i++){
            if (!globalCheckMark.includes(JSON.stringify(data[i]))){
                return false;
            }
        }
        return true;
    }

    refreshUsers = (data, allUsersCheckedList, globalCheckMark) => {
        this.setState({subscriberList: data})
        let columns =[
            {
                label: <div  className="checkbox checkbox-primary mg-top-10" >
                <input id={"checkbox-primary-head1"} type="checkbox"
                    onChange={this.multiSelect} value = {JSON.stringify(data)}
                    checked={this.checkIfEveryElementExisits(data, globalCheckMark)}/>
                    <label htmlFor={"checkbox-primary-head1"}></label>
                </div>,
                field: 'checkbox',
                sort: 'disabled',
            },
            {
                label: 'User',
                field: 'user',
            },
            {
                label: 'First Name',
                field: 'first_name',
            },
            {
                label: 'Last Name',
                field: 'last_name',
            },
            {
                label: 'Login',
                field: 'subscriber_login',
            },
            {
                label: 'Email',
                field: 'email',
            },
            {
                label: 'Scope',
                field: 'scope',
            },
            {
                label: 'Enable User',
                field: 'enable',
                sort: 'disabled',
            },
            {
                label: 'CallerID Numbers',
                field: 'callerIdNumbers',
                sort: 'disabled',
            },
            {
                label: 'Shared VM',
                field: 'sharedVM',
                sort: 'disabled',
            },
            {
                label: 'Send QR Code',
                field: 'qr',
                sort: 'disabled',
            },
            {
                label: 'User Devices',
                field: 'phone',
                sort: 'disabled',
            },
        ];
        if(JSON.parse(localStorage.getItem('creds')).user_type === "admin"){
            columns.push({
                label: 'Re-Provision',
                field: 'reprovision',
                sort: 'disabled',
            })
        }
        let dataModified = null;
        if(data != null)
        {
            dataModified = data.map((row, index)=>{
                return ({...row, checkbox: <div  className="checkbox checkbox-primary mg-top-10" >
                    <input id={"checkbox-table-"+index} type="checkbox" value={JSON.stringify(row)}
                        onChange={this.singleSelect} checked={globalCheckMark.includes(JSON.stringify(row))}/>
                        <label htmlFor={"checkbox-table-"+index}></label>
                    </div>,
                    enable: <div> 
                        {
                            allUsersCheckedList.includes("&"+row.subscriber_login.split("@")[0]+"&")?
                                <i className="fas fa-toggle-on data-table-icon mg-20" 
                                    onClick={this.userChangeHandler} 
                                    title="Disable Provisioning on Custom Web Tab" 
                                    data-value={row.subscriber_login.split("@")[0]} style={{color: "green"}}
                                    data-name="on"/>
                            :
                                <i className="fas fa-toggle-off data-table-icon mg-20" 
                                    title="Enable Provisioning on Custom Web Tab"
                                    onClick={this.userChangeHandler}  
                                    data-value={row.subscriber_login.split("@")[0]}
                                    data-name="off"/>
                        }
                    </div>, 
                    sharedVM: <i className="fa fa-envelope data-table-icon mg-20"
                        onClick={this.sharedVMHandler} data-id={row.user}></i>,
                    callerIdNumbers:<FontAwesomeIcon
                        title='Resynch All Domains'
                        icon={faPhoneVolume} className="icon-check"
                        style={{ fontSize: "18px", cursor: "pointer", marginLeft: '20px', transform: 'rotate(-45deg)'}}
                        onClick={e => this.callerIdHandler(row.user)}/>,
                    reprovision: <i className="fa fa-refresh data-table-icon mg-20"
                        onClick={this.reprovsionHandler} data-id={row.user}></i>,
                    phone: <i className="fa fa-phone data-table-icon mg-20"
                        onClick={this.editHandler} data-id={row.user} data-email={row.subscriber_login}></i>,
                
                    qr: <i className="fas fa-qrcode data-table-icon mg-20"
                        onClick={this.qrHandler} data-id={row.user} data-email={row.email}></i>});
            });
        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
    }
    multiSelect = (event) => {
        let emailList = "";
        const values = JSON.parse(event.target.value);
        for(let i=0; i< (values).length; i++){
            if(event.target.checked){
                emailList += values[i].email + ", "
            }
        }
        if (emailList.length > 0){
            emailList =  emailList.slice(0, -2);
        }
        this.setState({globalEmailList: emailList});
        if(event.target.checked) {
            this.setState({globalCheckMark: event.target.value});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, event.target.value);
        } else {
            this.setState({globalCheckMark: JSON.stringify(new Array())});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, JSON.stringify(new Array()));
        }
        
    }
    singleSelect = (event) => {
        const value = JSON.parse(event.target.value);
        let emailList =  this.state.globalEmailList;
        if(event.target.checked){
            if (emailList.length > 0){
                //this means that there are already some values in the list
                emailList += ", " + value.email
            } else {
                emailList += value.email
            }
        } else {
            // first checking with ", " that means its either first or second in n
            emailList =  emailList.replaceAll(value.email+ ", ", "")
            // if its the last in n items
            emailList =  emailList.replaceAll(", " + value.email, "")
            // if its the only one
            emailList =  emailList.replaceAll(value.email, "")
        }
        this.setState({globalEmailList: emailList});
        if(event.target.checked) {
            let tempCheckMark = JSON.parse(this.state.globalCheckMark);
            tempCheckMark.push(value)
            this.setState({globalCheckMark: JSON.stringify(tempCheckMark)});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, JSON.stringify(tempCheckMark));
        } else {
            
            let tempCheckMark = this.state.globalCheckMark;
            tempCheckMark = tempCheckMark.replaceAll(event.target.value + ",", "")
            tempCheckMark = tempCheckMark.replaceAll(event.target.value, "")
            tempCheckMark = tempCheckMark.replaceAll("},]", "}]")
            this.setState({globalCheckMark: tempCheckMark});
            this.refreshUsers(this.state.mySubList, this.state.allUsersCheckedList, tempCheckMark);
        }
    }
    checkEmailHandler = (evt) => {
        this.setState({goToCheckEmailPage: true});
    }
    reprovsionHandler = (evt) => {
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to reprovision this user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            extension: evt.target.getAttribute("data-id"),
                            domain: this.state.selectedDomain,
                            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}reprovisionByForce`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("The user will be reprovisioned!");
                            this.setState({style: 'custom-spinner-hide'});
                        })
                        .catch(err=>{
                            toast.error("Failed to reprovision");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    reprovsionDomainHandler = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type === "simple user") {
            return;
        }
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to reprovision all users in this domain?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            domain: this.state.selectedDomain,
                            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}reprovisionByForce`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("All users in this domain will be reprovisioned in few seconds!");
                            this.setState({style: 'custom-spinner-hide'});
                        })
                        .catch(err=>{
                            toast.error("Failed to reprovision");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    //============================================================================================================================================
    DomainLevelDeviceSyncHandler = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type === "simple user") {
            return;
        }
        confirmAlert({
            title: 'Alert!',
            message: 'Are you sure you want to sync all devices in this domain?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            domain: this.state.selectedDomain,
                            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}DomainLevelDeviceSync`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("All Devices in this domain will be synched in few seconds!");
                            this.setState({style: 'custom-spinner-hide'});
                        })
                        .catch(err=>{
                            toast.error("Failed to Sync devices");
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    //============================================================================================================================================
    
    sharedVMHandler = async (evt) => {
        this.setState({sharedVmUser: evt.target.getAttribute("data-id")});
        await this.getSharedVmList(evt.target.getAttribute("data-id"));
        this.setState({sharedVmModal: true});
    }
    getSharedVmList = (forUser) => {
        const payLoad = {
            for_user: forUser,
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}sharedVMSummary`, payLoad, this.headers)
        .then(response=>{
            this.setState({sharedVmList: response.data});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    subscriberChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedSubscriber: ""});
            return;
        }
        this.setState({selectedSubscriber: result[0].user});
    }
    addSubscriberHandler = (evt) => {
        if(!this.state.selectedSubscriber){
            toast.error("Please select the subscriber first!")
            return;
        }
        if(this.state.sharedVmList.length >= 3){
            toast.error("You cannot have more than 3 shared voicemails!")
            return;
        }
        if(this.state.selectedSubscriber === this.state.sharedVmUser){
            toast.error("You cannot select itself in the shared voicemail!")
            return;
        }
        if(this.state.sharedVmList.find(item => {
            return item.vm_user === this.state.selectedSubscriber;
        })){
            toast.error("This subscriber has already been added")
            return;
        }
        
        const payLoad = {
            for_user: this.state.sharedVmUser,
            vm_user: this.state.selectedSubscriber,
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}createUpdateSharedVM`, payLoad, this.headers)
        .then(response=>{
            let sharedVmList = this.state.sharedVmList;
            sharedVmList.push({vm_user:this.state.selectedSubscriber})
            this.setState({sharedVmList: sharedVmList})
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    deleteSharedVmHander =(evt) => {
        
        const payLoad = {
            for_user: this.state.sharedVmUser,
            vm_user: evt.target.getAttribute("data-id"),
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}deleteSharedVM`, payLoad, this.headers)
        .then(response=>{
            let sharedVmList = this.state.sharedVmList;
            const index = sharedVmList.findIndex((item,index) => {
                return item.vm_user === evt.target.getAttribute("data-id")
            })
            
            sharedVmList.splice(index, 1);
            this.setState({sharedVmList: sharedVmList});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    toggleCallingNumberHandler = () => {
        this.setState({callingNumberModal: !this.state.callingNumberModal});
    }

    callerIdHandler = (user) => {
        this.setState({ extensionSelected:user})
        this.refreshCallingIdLists(user);
    }
    refreshCallingIdLists = (user) => {
        
        let payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}getAvailableCallingNumbers`, payLoad, this.headers)
        .then(response=>{
            
            let items = [];
            response.data.map((row, index)=>{
                items.push({phone_number: row.phone_number.toString()})
            });
            this.setState({availableCallingNumbers: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

        payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user: user
        }
        axios.post(`${process.env.REACT_APP_API_URL}getAssignedCallingNumbers`, payLoad, this.headers)
        .then(response=>{
            let dataFromApi = response.data;
            let columns =[
                {
                    label: 'Id',
                    field: 'id',
                },
                {
                    label: 'Phone Number',
                    field: 'phone_number',
                },
                {
                    label: 'Extension',
                    field: 'extension',
                },
                {
                    label: 'Domain',
                    field: 'domain',
                },
                {
                    label: 'Delete',
                    field: 'edit',
                    sort: 'disabled',
                }
            ];
    
            let dataModified = null;
            if(dataFromApi != null)
            {
                dataModified = dataFromApi.map((row)=>{
                    return ({...row, edit:  <div className="edit-column">
                    <i className="far fa-trash-alt data-table-icon"
                        onClick={e => this.removePhoneNumberHandler(row.phone_number)}
                    ></i>
                    </div>});
                });
    
            }
            this.setState({assignedCallingNumbers: {columns: columns, rows: dataModified}});
            this.setState({callingNumberModal: true});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    removePhoneNumberHandler = (phoneNumber) => {
        console.log("Delete Phonenumber "+phoneNumber);
        const payLoad = {
            phone_number : phoneNumber,
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
        };
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}deleteAssignedCallingNumber`, payLoad, this.headers)
        .then(response=>{
            this.refreshCallingIdLists(this.state.extensionSelected);
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    CallingNumberChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedCallingNumber: ""});
            return;
        }
        this.setState({selectedCallingPhoneNumber: result[0].phone_number});
        this.setState({style: 'custom-spinner'});

    }
    saveCallingNumberHandler =() => {
        if(!this.state.selectedCallingPhoneNumber){
            toast.error("Please select the calling number first!")
            return;
        }
        const payLoad = {
            domain: this.state.selectedDomain,
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            extension: this.state.extensionSelected,
            phone_number: this.state.selectedCallingPhoneNumber
        }
        this.setState({style: 'custom-spinner'});
        axios.post(`${process.env.REACT_APP_API_URL}assignCallingNumber`, payLoad, this.headers)
        .then(response=>{
            this.setState({style: 'custom-spinner-hide'});
            this.refreshCallingIdLists(this.state.extensionSelected);    
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    domainLevelQuickDialToggle = (evt) => {
        if(JSON.parse(localStorage.getItem('creds')).user_type === "simple user") {
            return;
        }
        const value = evt.target.getAttribute("data-action") === "add";
        confirmAlert({
            title: 'Alert!',
            message: `This will ${value?"show":"hide"} quick dials for all users within this domain, are you sure?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const payLoad = {
                            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
                            domain : this.state.selectedDomain,
                            check: value,
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                            username: JSON.parse(localStorage.getItem('creds')).username,
                        }
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}toggleDomainLevlQuickDial`, payLoad, this.headers)
                        .then(response=>{
                            this.setState({style: 'custom-spinner-hide'});
                            if(value){
                                this.setState({domainLevelQuickDial: true});
                            } else {
                                this.setState({domainLevelQuickDial: false});
                            }
                            
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                            
                        });
                    }
                },
                {
                    label: 'No',
                        onClick: () => {
                        }
                }
            ]
            
        });
        
    }

    checkQuickDial = (domain) => {
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            domain : domain,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        }
        let value = true;
        axios.post(`${process.env.REACT_APP_API_URL}getQuickDialDomain`, payLoad, this.headers)
        .then(response=>{
            this.setState({domainLevelQuickDial: true})
        })
        .catch(err=>{
            this.setState({domainLevelQuickDial: false})
        });
    }

    render () {
        let redirect = null;
        if(this.state.idSelected){
            this.setState({idSelected: false});
           // alert(this.state.email);
            redirect = <Redirect to = {{
                pathname: "/app_management/user_devices",
                state: { domain: this.state.selectedPreDomain,login:this.state.loginSelected
                }
            }} />;
        }

        
        if(this.state.goToCheckEmailPage){
            redirect = <Redirect to = {{
                    pathname: "/app_management/check_email"
            }} />;
        }
        return (
            <Fragment>
                <Breadcrumb parent="App" title="App Management" />
                {redirect}
                <QRModal
                    show={this.state.qrModal}
                    toggleHandler={this.toggleHandler}
                    onChangeHandler = {this.onChangeHandler}
                    sendEmailHandler = {this.sendEmailHandler}
                    editorOnChangeHandler={this.editorOnChangeHandler}
                    cc = {this.state.cc}
                    body= {this.state.body}
                    to = {this.state.to}
                    subject= {this.state.subject}
                    templatesList = {this.state.templatesList}
                    showTemplateSave={this.state.showTemplateSave}
                    template_name={this.state.template_name}
                    toggleTemplateSave={this.toggleTemplateSave}
                    templateSelected={this.state.templateSelected}
                    saveTemplateHandler={this.saveTemplateHandler}/>

                <CallingNumberModal
                    show={this.state.callingNumberModal}
                    toggleHandler={this.toggleCallingNumberHandler}
                    availableCallingNumbers={this.state.availableCallingNumbers}
                    assignedCallingNumbers={this.state.assignedCallingNumbers}
                    CallingNumberChangeHandler={this.CallingNumberChangeHandler}
                    saveCallingNumberHandler={this.saveCallingNumberHandler}
                    selectedUser={this.state.extensionSelected}
                />
                <SharedVM
                    show={this.state.sharedVmModal}
                    sharedVmUser={this.state.sharedVmUser}
                    toggleHandler={this.sharedVmToggleHandler}
                    subscriberList={this.state.subscriberList}
                    addSubscriberHandler={this.addSubscriberHandler}
                    subscriberChangeHandler={this.subscriberChangeHandler}
                    sharedVmList={this.state.sharedVmList}
                    deleteSharedVmHander={this.deleteSharedVmHander}/>
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Domain</h5>
                            <button className="btn btn-warning check-email-btn"
                                type="button" onClick={this.checkEmailHandler}>Check Email</button>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="theme-form">
                                    <div className="form-group">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-typeahead"
                                            className="search-domains"
                                            labelKey="domain"
                                            multiple={false}
                                            selected={this.state.previouslySelectedDomain}
                                            options={this.state.domainList}
                                            onChange={this.domainChangeHandler}
                                            placeholder="Choose a domain..."
                                        />
                                        
                                    </div>
                                </form>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-1 col-sm-0"></div>
                </div>
                {
                    this.state.selectedDomain !== "" && this.state.dataTable?
                        <div>
                            <div className="container-fluid main-container">

                                <Card className='col-md-12 col-sm-12'>
                                    <CardHeader >
                                        <h5 className="mb-4">Domain Level Settings</h5>
                                        <div className='flex gap-40'>
                                            <div className="flex-col"> 
                                                <label>Domain Device Limit</label>
                                                <div className="flex">
                                                    <input type="number"  className="form-control" min="1" max="20" value={this.state.device_limit} style={{width: "80px"}}  onChange={this.onChangeHandler} name="device_limit" disabled={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"} />
                                                    <button className="btn btn-dark" type="button" onClick={this.limitChangeHandler}
                                                    style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{padding: "5px", marginLeft: "5px", marginTop: "-4px", opacity:"0.3"}:{padding: "5px", marginLeft: "5px", marginTop: "-4px"}}>Save</button>
                                                </div>
                                                
                                                    
                                            </div>
                                            <div className="flex-col justify-center items-center"> 
                                                <label>Domain Level Provisioning</label><br/>
                                                {
                                                    this.state.provisioningDomainChecked?
                                                    <i className="fas fa-toggle-on data-table-icon" 
                                                        onClick={this.provisioningDomainToggle} 
                                                        title="Disable Domain Level Provisioning"
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{color: "green", opacity: "0.3"}:{color: "green"}}
                                                        data-action="remove"/>
                                                :
                                                    <i className="fas fa-toggle-off data-table-icon" 
                                                        title="Enable Domain Level Provisioning"
                                                        onClick={this.provisioningDomainToggle}
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{opacity: "0.3"}:{}}
                                                        data-action="add"/>
                                                }
                                
                                            </div>

                                            <div className="flex-col justify-center items-center"> 
                                                <label>Domain Level Quick Dial<br/><span style={{marginLeft: "48px"}}>Hide/Show</span></label>
                                                {
                                                    this.state.domainLevelQuickDial?
                                                    <i className="fas fa-toggle-on data-table-icon" 
                                                        onClick={this.domainLevelQuickDialToggle} 
                                                        title="Show Quick Dials for all users"
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{color: "green", opacity:"0.3"}:{color: "green"}}
                                                        data-action="remove"/>
                                                :
                                                    <i className="fas fa-toggle-off data-table-icon" 
                                                        title="Hide Quick Dials for all users"
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{opacity:"0.3"}:{}}
                                                        onClick={this.domainLevelQuickDialToggle}
                                                        data-action="add"/>
                                                }
                                
                                            </div>

                                            
                                            {
                                                JSON.parse(localStorage.getItem('creds')).user_type === "admin"?
                                                <div className="flex-col justify-center items-center"> 
                                                    <label>Domain Level Reprovisioning</label><br/>
                                                    <i className="fa fa-refresh data-table-icon"
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{opacity:"0.3"}:{}}    
                                                                onClick={this.reprovsionDomainHandler}/>
                                    
                                                </div>
                                                :null
                                            }
                                                
                                            
                                            {
                                                JSON.parse(localStorage.getItem('creds')).user_type === "admin"?
                                                <div className="flex-col justify-center items-center"> 
                                                    <label>Domain Level Device Sync</label><br/>
                                                    <i className="fa fa-refresh data-table-icon mg-right"
                                                        style={JSON.parse(localStorage.getItem('creds')).user_type === "simple user"?{opacity:"0.3"}:{}} 
                                                                onClick={this.DomainLevelDeviceSyncHandler}/>

                                                </div>
                                                :null
                                            }
                                            
                                        </div>
                                        
                                    </CardHeader>
                                </Card>
                            </div>
                            <div className="container-fluid main-container">
                            
                                <Card className='col-md-12 col-sm-12'>
                                    <CardHeader >
                                        <h5>Subscribers</h5>          
                                    </CardHeader>
                                    <CardBody className="dropdown-basic">
                                        <MDBDataTableV5 hover entriesOptions={[10, 25, 50, 100]} 
                                            entries={10}
                                            pagesAmount={4} data={this.state.dataTable} 
                                            searchTop  searchBottom={false}/>
                                        
                                        <div className="btn-container">
                                            <button className="btn btn-primary" type="button" onClick={this.saveHandler}>Save</button>
                                            <button className="btn btn-secondary" type="button" onClick={this.qrCodeHandler} style={{marginLeft: "15px"}}>
                                                Send QR Code
                                            </button>
                                        </div>
                                    </CardBody>
                                    
                                </Card>
                                    
                            
                            </div>
                        </div>
                    :
                    null
                }
            </Fragment>
        );
    }
    
};
export default Extensions;