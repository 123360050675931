import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'

class Portability extends React.Component {

	state = {
		data: {
		},
		peerless: {
		},
		vi: {
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler =(event) => {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}
	peerlessInputChangeHandler =(event) => {
		var peerless = this.state.peerless;
		peerless[event.target.name] = event.target.value;
		this.setState({ peerless });

	}
	viInputChangeHandler =(event) => {
		var vi = this.state.vi;
		vi[event.target.name] = event.target.value;
		this.setState({ vi });

	}


	componentDidMount() {
		if( JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
		this.populateIneliquent();
		this.populatePeerless();
		this.populateVi();

	}
    populateIneliquent = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        };
        axios.post(`${process.env.REACT_APP_API_URL}inteliquentSummary`, payLoad, this.headers)
        .then(response => {
            var data = this.state.data;
            if(response.data.length === 0){
                this.setState({style: 'custom-spinner-hide'});
                data["subscriber_id"] = "";
                data["id"] = "";
                data["name"] = "";
                data["api_url"] = "";
                data["token_url"]= "";
                data["client_secret"] = "";
                data["client_id"] = "";
                data["created_user"] = "";
                this.setState({data});
                return;
            }
            data = response.data[0];
            delete data["cloud_id"];
            delete data["company"];
            this.setState({ data });
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    populatePeerless = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        };
        axios.post(`${process.env.REACT_APP_API_URL}peerlessSummary`, payLoad, this.headers)
        .then(response => {
            var peerless = this.state.peerless;
            if(response.data.length === 0){
                this.setState({style: 'custom-spinner-hide'});
                peerless["subscriber_id"] = "";
                peerless["id"] = "";
                peerless["name"] = "";
                peerless["api_url"] = "";
                peerless["customer"]= "";
                peerless["pass_code"] = "";
                peerless["created_user"] = "";
                this.setState({peerless});
                return;
            }
            peerless = response.data[0];
            delete peerless["cloud_id"];
            delete peerless["company"];
            this.setState({ peerless });
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    populateVi = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        };
        axios.post(`${process.env.REACT_APP_API_URL}viSummary`, payLoad, this.headers)
        .then(response => {
            var vi = this.state.vi;
            if(response.data.length === 0){
                this.setState({style: 'custom-spinner-hide'});
                vi["subscriber_id"] = "";
                vi["id"] = "";
                vi["name"] = "";
                vi["api_url"] = "";
                vi["login"]= "";
                vi["secret"] = "";
                vi["created_user"] = "";
                this.setState({vi});
                return;
            }
            vi = response.data[0];
            delete vi["cloud_id"];
            delete vi["company"];
            this.setState({ vi });
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
        });
    }

	saveHandler = (event) => {
		event.preventDefault();
		if (!this.state.data.name || !this.state.data.api_url || !this.state.data.client_id 
            || !this.state.data.token_url || !this.state.data.client_secret) {
            toast.error('All fields are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id;
        if (payLoad["id"] === ""){
            delete payLoad["id"];
        }
		axios.post(`${process.env.REACT_APP_API_URL}inteliquentCreateUpdate`, payLoad, this.headers)
		.then(response => {
            this.populateIneliquent();
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}


    deleteHandler = (event) =>{
        confirmAlert({
            title: 'Are you sure you want to delete this Inteliquent detail?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : this.state.data.id,
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteInteliquent`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.populateIneliquent();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

	peerlessSaveHandler = (event) => {
		event.preventDefault();
		if (!this.state.peerless.name || !this.state.peerless.api_url || !this.state.peerless.customer 
            || !this.state.peerless.pass_code || !this.state.peerless.user_id) {
            toast.error('All fields are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		const payLoad = this.state.peerless;
        payLoad["a_user_id"] = JSON.parse(localStorage.getItem('creds')).id;
        if (payLoad["id"] === ""){
            delete payLoad["id"];
        }
		axios.post(`${process.env.REACT_APP_API_URL}peerlessCreateUpdate`, payLoad, this.headers)
		.then(response => {
            this.populatePeerless();
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}


    peerlessDeleteHandler = (event) =>{
        confirmAlert({
            title: 'Are you sure you want to delete this Peerless detail?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : this.state.peerless.id,
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deletePeerless`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.populatePeerless();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }
    
	viSaveHandler = (event) => {
		event.preventDefault();
		if (!this.state.vi.name || !this.state.vi.api_url || !this.state.vi.login 
            || !this.state.vi.secret) {
            toast.error('All fields are mandatory!');
            return;
		}

		this.setState({style: 'custom-spinner'});
		const payLoad = this.state.vi;
        payLoad["a_user_id"] = JSON.parse(localStorage.getItem('creds')).id;
        if (payLoad["id"] === ""){
            delete payLoad["id"];
        }
		axios.post(`${process.env.REACT_APP_API_URL}viCreateUpdate`, payLoad, this.headers)
		.then(response => {
            this.populatePeerless();
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}


    viDeleteHandler = (event) =>{
        confirmAlert({
            title: 'Are you sure you want to delete this VOIP Innovations detail?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            id : this.state.vi.id,
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteVi`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.populatePeerless();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

	render() {

		let redirect = null;
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="App" title="Portability" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <div className="row">
                                <div className="col-md-10">
                                    <h5>{this.state.data.id? "Edit Inteliquent" : "Add Inteliquent"}</h5>
                                </div>
                                <div className="col-md-2" style={{textAlign: "end"}}>
                                {
                                    this.state.data.id? <FontAwesomeIcon 
                                    icon={faTrashAlt} className="icon-check"
                                    style={{fontSize: "20px", color: "red", cursor:"pointer"}}
                                    onClick={this.deleteHandler}/> 

                                    : null
                                }
                                </div>
                            </div>
                            
                            
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="name"
                                        onChange={this.inputChangeHandler} value={this.state.data.name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Token Url
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="token_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.token_url}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        API URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="api_url"
                                        onChange={this.inputChangeHandler} value={this.state.data.api_url}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Client Secret
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="client_secret"
                                        onChange={this.inputChangeHandler} value={this.state.data.client_secret}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Client Id
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="client_id"
                                    onChange={this.inputChangeHandler} value={this.state.data.client_id}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        {this.state.data.id? "Update": "ADD"}
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>

                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <div className="row">
                                <div className="col-md-10">
                                    <h5>{this.state.peerless.id? "Edit Peerless" : "Add Peerless"}</h5>
                                </div>
                                <div className="col-md-2" style={{textAlign: "end"}}>
                                {
                                    this.state.peerless.id? <FontAwesomeIcon 
                                    icon={faTrashAlt} className="icon-check"
                                    style={{fontSize: "20px", color: "red", cursor:"pointer"}}
                                    onClick={this.peerlessDeleteHandler}/> 

                                    : null
                                }
                                </div>
                            </div>
                            
                            
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="name"
                                        onChange={this.peerlessInputChangeHandler} value={this.state.peerless.name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Customer
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="customer"
                                        onChange={this.peerlessInputChangeHandler} value={this.state.peerless.customer}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        API URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="api_url"
                                        onChange={this.peerlessInputChangeHandler} value={this.state.peerless.api_url}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Pass Code
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="pass_code"
                                        onChange={this.peerlessInputChangeHandler} value={this.state.peerless.pass_code}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        User Id
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="user_id"
                                    onChange={this.peerlessInputChangeHandler} value={this.state.peerless.user_id}/>
                                </div>
                            </div>


                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.peerlessSaveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        {this.state.peerless.id? "Update": "ADD"}
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>


                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <div className="row">
                                <div className="col-md-10">
                                    <h5>{this.state.data.id? "Edit VOIP Innovations" : "Add VOIP Innovations"}</h5>
                                </div>
                                <div className="col-md-2" style={{textAlign: "end"}}>
                                {
                                    this.state.vi.id? <FontAwesomeIcon 
                                    icon={faTrashAlt} className="icon-check"
                                    style={{fontSize: "20px", color: "red", cursor:"pointer"}}
                                    onClick={this.viDeleteHandler}/> 

                                    : null
                                }
                                </div>
                            </div>
                            
                            
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="name"
                                        onChange={this.viInputChangeHandler} value={this.state.vi.name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Login
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="login"
                                        onChange={this.viInputChangeHandler} value={this.state.vi.login}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        API URL
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="api_url"
                                        onChange={this.viInputChangeHandler} value={this.state.vi.api_url}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Secret
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="secret"
                                        onChange={this.viInputChangeHandler} value={this.state.vi.secret}/>
                                </div>
                            </div>


                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.viSaveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        {this.state.vi.id? "Update": "ADD"}
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Portability;
