import {
    Home,
    Users,
    Globe,
    FileText,
    Cloud,
    Clipboard,
    Star,
    Server,
    Image,
    Smartphone,
    PenTool,
    BarChart2,
    Slash,
    Share2,
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: true, path: '/dashboard', lineBelow: false
    },
    // {
    //     title: 'SMS/MMS Domain', icon: Globe, type: 'link', active: false, path: '/domains'
    // },
    {
        title: 'App Management', icon: Smartphone, type: 'link', active: false, path: '/app_management', lineBelow: false
    },
    {
        title: 'Blocked Subscribers', icon: Slash, type: 'link', active: false, path: '/blocked_subscribers', lineBelow: false
    },
    {
        title: 'Region', icon: Globe, type: 'link', active: false, path: '/region', lineBelow: false
    },
    {
        title: 'Core Management', icon: Server, type: 'link', active: false, path: '/core_management', lineBelow: false
    },
    {
        title: 'System Logs', icon: FileText, type: 'link', active: false, path: '/logs', lineBelow: true
    },
    {
        title: 'MAMT Login Users', icon: Users, type: 'link', active: false, path: '/users', lineBelow: true
    },
    {
        title: 'Subscribers', icon: Star, type: 'link', active: false, path: '/subscribers', lineBelow: false
    },
    {
        title: 'Netsapiens', icon: Cloud, type: 'link', active: false, path: '/netsapiens', lineBelow: false
    },
    {
        title: 'Client Profiles', icon: Clipboard, type: 'link', active: false, path: '/client_profiles', lineBelow: false
    },
    {
        title: 'Gallery', icon: Image, type: 'link', active: false, path: '/gallery', lineBelow: true
    },
    {
        title: 'Domain Report', icon: BarChart2, type: 'link', active: false, path: '/domains_report', lineBelow: false
    },
    {
        title: 'Portability', icon: PenTool, type: 'link', active: false, path: '/portability', lineBelow: false
    },
    {
        title: 'Vinsolution', icon: Share2, type: 'link', active: false, path: '/vn_dealers', lineBelow: false
    },
    {
        title: 'Release Notes', icon: FileText, type: 'link', active: false, path: '/ReleaseNotes', lineBelow: false
    }
    
]
