import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import { CSVLink } from "react-csv";

import { Typeahead } from 'react-bootstrap-typeahead';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class Billing extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            style: 'custom-spinner',
            loading: true,
		    backToIndex: false,
            domainList: [],
            subscriberList: [],
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    csvHeaders = [
        { label: "Subscriber Level Provisioning", key: "subscriber_global" },
        { label: "Domain", key: "domain" },
        { label: "Domain Level Provisioning", key: "domain_global" },
        { label: "Active Subscribers", key: "active_subscribers" },
    ];

    componentDidMount(){
        console.log("here")
        if(JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO"){
            this.populateSubscribers();
        } else {
            this.populateBilling(JSON.parse(localStorage.getItem('creds')).cloud_id, JSON.parse(localStorage.getItem('creds')).superuser)
        }
        
    }

    populateSubscribers = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}subscriberList`, payLoad, this.headers)
        .then(response=>{
            let items = [];
            response.data.map((row, index)=>(
                items.push({cloud_id: row.cloud_id, superuser: row.superuser})
            ));

            this.setState({subscriberList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}userSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Subscriber Level Provisioning',
                field: 'subscriber_global',
            },
            {
                label: 'Domain',
                field: 'domain',
            },
            {
                label: 'Domain Level Provisioning',
                field: 'domain_global',
            },
            {
                label: 'Active Subscribers',
                field: 'active_subscribers',
            },
        ];
        if (JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO") {
            columns =[
                {
                    label: 'Id',
                    field: 'id',
                },
                {
                    label: 'Domain',
                    field: 'domain',
                },
                {
                    label: 'Domain Level Provisioning',
                    field: 'domain_global',
                },
                {
                    label: 'Active Subscribers',
                    field: 'active_subscribers',
                },
            ];
        }

        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                if(row.subscriber_global){
                    row.subscriber_global = "Enabled"
                }
                else {
                    row.subscriber_global = "Disabled"
                }

                if(row.domain_global){
                    row.domain_global = "Enabled"
                }
                else {
                    row.domain_global = "Disabled"
                }
                return({...row});
            });

        }
        this.setState({csvData: dataFromApi});
        this.setState({dataTable: {columns: columns, rows: dataFromApi}});
        this.setState({loading: false});
    }

    subscriberChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({subscriberSelected: ""});
            this.setState({domainSelected: ""});
            return;
        }
        
        this.populateBilling(result[0].cloud_id, result[0].superuser)
    }

    populateBilling = (cloudId, superUser) => {
        console.log(cloudId);
        this.setState({subscriberSelected: cloudId});
        this.setState({style: 'custom-spinner'});
        const payLoad = new FormData();
        payLoad.append('cloud_id', cloudId);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=domain&action=read&utype=${'s'}&cloud_username=${superUser}&cloud_password=${"password"}`, payLoad)
        .then(response=>{

            let items = [];
            response.data.map((row, index)=>(
                items.push({domain: row.domain})
            ));

            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    searchHandler = () =>{
        if(!this.state.subscriberSelected){
            toast.error("You have to select the subscriber first!");
            return;
        }
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id: this.state.subscriberSelected
        };

        if(this.state.selectedDomain){
            payLoad["domain"] = this.state.selectedDomain;
        }

        axios.post(`${process.env.REACT_APP_API_URL}billingSummary`, payLoad, this.headers)
        .then(response=>{
            
            this.setState({style: 'custom-spinner-hide'});
            this.createDataTable(response.data);
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    domainChangeHandler = (result) => {
        if(result.length === 0){
            this.setState({selectedDomain: ""});
            this.setState({usersList: []});
            return;
        }
        this.setState({selectedDomain: result[0].domain});
    }


    render() {

        let redirect = null;

        if(this.state.backToIndex){
            redirect = <Redirect to = {{
                    pathname: "/index"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="Domain Report" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className="container-fluid main-container">
                    <div className="col-md-1 col-sm-0"></div>
                    <Card className="col-md-10 col-sm-12">
                        <CardHeader>
                            <h5>Choose Details</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                {
                                    JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO"? 
                                        <form className="theme-form">
                                            <div className="form-group">
                                                <i className="fa fa-search type-ahead-search-icon"/>
                                                <Typeahead
                                                    id="basic-typeahead"
                                                    className="search-domains"
                                                    labelKey="cloud_id"
                                                    multiple={false}
                                                    options={this.state.subscriberList}
                                                    onChange={this.subscriberChangeHandler}
                                                    placeholder="Choose a subscriber..."
                                                />
                                            </div>
                                        </form>
                                    : null
                                }
                                
                                {
                                    this.state.subscriberSelected?
                                        <div id="the-basics">
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <i className="fa fa-search type-ahead-search-icon"/>
                                                    <Typeahead
                                                        id="basic-typeahead"
                                                        className="search-domains"
                                                        labelKey="domain"
                                                        multiple={false}
                                                        options={this.state.domainList}
                                                        onChange={this.domainChangeHandler}
                                                        placeholder="Choose a domain..."
                                                    />
                                                </div>
                                            </form>
                                    
                                        </div>
                                    : null
                                }
                            </div>

                            <div className="col-md-12" style={{textAlign: "center"}}>
                                <button onClick={this.searchHandler} type="button" 
                                    className="btn btn-rounded-custom btn-primary table-btn" >Search </button>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-1 col-sm-0"></div>
                    
                </div>

                <div className = "MainIndex">
                    <div className="row" style={{float:"right"}}>
                        {   this.state.csvData? 
                                <button type="button"  className="btn btn-rounded-custom btn-secondary export-button">
                                    <CSVLink style={{color: "white"}}data={this.state.csvData} headers={this.csvHeaders} filename="Billing.csv">Export to CSV</CSVLink>
                                </button> 
                            : null 
                        }
                    </div>
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default Billing;
