import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            transport: "udp",
            pushblockreg: "0",
            ringingtone: "periodic(sine(2000ms,2500,440Hz),silence(4000ms))",
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    transportItems = [];

    populateItems = (data) => {

        let items = [];
        items.push(<option value={""}></option>);
        data.map((row)=>(
            items.push(<option value={row}>{row}</option>)
        ));

        return items;

    }

    populateDefaults(){
        axios.get(`${process.env.REACT_APP_API_URL}getDefaults`, this.headers)
            .then(response=>{

                let items = [];

                items = this.populateItems(response.data.transport.Allowed);
                this.transportItems = items;

            })
            .catch(err=>{
                toast.error(err.message);

            }
        );
    }

	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		

        if(event.target.name === "subscriber_id"){
            const index = event.target.selectedIndex;
            data["cloud_id"] = event.target[index].text;
            this.getDomains(event.target[index].text);
        }
        this.setState({ data });

        if (event.target.name === "domain"){
            if(event.target.value === ""){
                this.setState({extension: ""});
                return;
            }
            this.getExtensions(event.target.value, this.state.data.cloud_id);
        }

	}

    getSubscribers = () => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }

        axios.post(`${process.env.REACT_APP_API_URL}getCloudIds`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            var data =  this.state.data;
            response.data.map((row)=>{
                if (JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO" && row.cloud_id  ===  JSON.parse(localStorage.getItem('creds')).cloud_id){
                    data["cloud_id"] = row.cloud_id;
                    data["subscriber_id"] = row.id;
                    this.setState({data});
                }
                items.push(<option value={row.id}>{row.cloud_id}</option>)
                return null;
            });
            this.subscribersItems = items;
            this.setState({subscribersItems: items})
        }).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
        });
    }


	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        this.populateDefaults();
        
        this.getSubscribers();
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: JSON.parse(localStorage.getItem('creds')).id
			};
			axios.post(`${process.env.REACT_APP_API_URL}profileDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
                this.getDomains(response.data.cloud_id);
                if(response.data.domain){
                    this.getExtensions(response.data.domain, response.data.cloud_id);
                }
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
                if (err.response){
                    toast.error(err.response.data);
                } else{
                    toast.error(err.message);
                }
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {
            this.getDomains();
			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.profilename || !this.state.data.subscriber_id || !this.state.data.outboundproxyenabled ||
                !this.state.data.outboundproxyhost || !this.state.data.transport || !this.state.data.ringingtone) {
            toast.error('Fields with * are mandatory!');
            return;
		}
        if(this.state.data.expires && this.state.data.expires.length > 3){
            toast.error("Expires value can not be greater than 3");
            return;
        }
		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id;
		axios.post(`${process.env.REACT_APP_API_URL}profileCreateUpdate`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    getDomains = (cloudId) => {
        this.setState({style: 'custom-spinner'});
        let payLoad = {}
        if(cloudId){
            payLoad["cloud_id"] = cloudId
        } else {
            payLoad["cloud_id"] = JSON.parse(localStorage.getItem('creds')).cloud_id;
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}getAllDomains`, payLoad, this.headers)
        .then(response=>{

            const domains = (response.data).sort(this.GetSortOrder("domain"));
            let items = [];
            items.push(<option value={""}></option>);
            domains.map((row)=>(
                items.push(<option value={row.domain}>{row.domain}</option>)
            ));
            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }
    getExtensions = (domain, cloudId) => {
        this.setState({style: 'custom-spinner'});
        this.setState({style: 'custom-spinner'});
        let payLoad = new FormData();
        payLoad.append('domain', domain);
        payLoad.append('fields', "user");
        payLoad.append('cloud_id',cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=s&cloud_username=${"100@"+domain}&cloud_password=${"password"}`, payLoad)
        .then(response=>{
            const extensions = (response.data).sort(this.GetSortOrder("user"));
            let items = [];
            items.push(<option value={""}></option>);
            extensions.map((row)=>(
                items.push(<option value={row.user}>{row.user}</option>)
            ));
            this.setState({extensionsList: items});
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/client_profiles',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Client Profiles" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Client Profile" : "Add Client Profile"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="profilename"
                                        onChange={this.inputChangeHandler} value={this.state.data.profilename}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Outbound Proxy Host
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="outboundproxyhost"
                                        onChange={this.inputChangeHandler} value={this.state.data.outboundproxyhost}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> 
                                        Out Bound Proxy Enabled  
                                    </label> <span> * </span>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "1"}
                                            /> Yes
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "0"}/> No
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left"> 
                                        Transport  
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="transport" 
                                        value={this.state.data.transport}>
                                        {this.transportItems}
                                    </select>
                                </div>
                                
                                
                            </div>
                            <div className="row form-group">

                                <div className="col-md-6 custom-radio">
                                    <label> Push Block Reg  </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="pushblockreg"
                                            checked={this.state.data.pushblockreg === "1"}
                                            /> Yes
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="pushblockreg"
                                            checked={this.state.data.pushblockreg === "0"}/> No
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Expires
                                    </label>
                                    <input type="number" className="form-control" name="expires"
                                        onChange={this.inputChangeHandler} value={this.state.data.expires}/>
                                </div>

                            </div>
                            <div className="row form-group">

                                <div className="col-md-12">
                                    <label className="float-left"> RingingTone  </label> <span> * </span>
                                    <input type="text" className="form-control" name="ringingtone"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.ringingtone}/>
                                </div>

                            </div>
                            

                            <div className="row form-group">
                                {
                                    JSON.parse(localStorage.getItem('creds')).cloud_id === "CREXDEMO" && this.state.subscribersItems? 
                                        <div className="col-md-6">
                                            <label> Subscriber  </label> <span> * </span>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="subscriber_id" 
                                                value={this.state.data.subscriber_id}>
                                                {this.state.subscribersItems}
                                            </select>
                                        </div>
                                    : null
                                }
                            </div>

                            <div className="row form-group">
                                {
                                    this.state.domainList?
                                        <div className="col-md-6">
                                            <label className="float-left"> 
                                                Domain  
                                            </label>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="domain" 
                                                value={this.state.data.domain}>
                                                {this.state.domainList}
                                            </select>
                                        </div>
                                    : null
                                }
                                
                                    

                                {
                                    this.state.data.domain?

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Extension
                                            </label>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="extension" 
                                                value={this.state.data.extension}>
                                                {this.state.extensionsList}
                                            </select> 
                                        </div>

                                    :null
                                }
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
