import React, { Fragment } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../assets/scss/app-styles/portability.scss'


class CheckPortability extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            style: 'custom-spinner-hide',
            numbers: "",
            showAllDetails: false,
            portabilityData: [],
        };
    }

    componentDidMount(){

        const url = new URL(window.location.href);
        const cloudId = url.searchParams.get("cloud_id");
        this.setState({cloudId: cloudId});
    }

    searchPortability = (e) => {
        if(!this.state.numbers){
            toast.error("Plase enter the list of numbers first");
            return;
        }
        const numbers= this.state.numbers.replaceAll("&", "");
        this.setState({style: 'custom-spinner'});
        this.setState({portabilityData: []});
        const payLoad = {
            cloud_id: this.state.cloudId,
            numbers: numbers,
        };

        axios.post(`${process.env.REACT_APP_API_URL}checkIntelliquentPortability`, payLoad)
        .then(response=>{
            let data = (this.state.portabilityData).concat(response.data)
            this.createDataTable(data, this.state.showAllDetails);
            this.setState({portabilityData: data});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            this.setState({style: 'custom-spinner-hide'});
            if (err.response){
                toast.error(err.response.data);
                return
            }
            toast.error(err.message);
        });


        axios.post(`${process.env.REACT_APP_API_URL}checkPeerlessPortability`, payLoad)
        .then(response=>{
            let data = (this.state.portabilityData).concat(response.data)
            this.createDataTable(data, this.state.showAllDetails);
            this.setState({portabilityData: data});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            this.setState({style: 'custom-spinner-hide'});
            if (err.response){
                toast.error(err.response.data);
                return
            }
            toast.error(err.message);
        });

        axios.post(`${process.env.REACT_APP_API_URL}checkVIPortability`, payLoad)
        .then(response=>{
            let data = (this.state.portabilityData).concat(response.data)
            this.createDataTable(data, this.state.showAllDetails);
            this.setState({portabilityData: data});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            this.setState({style: 'custom-spinner-hide'});
            if (err.response){
                toast.error(err.response.data);
                return
            }
            toast.error(err.message);
        });
    }

    filterDataFromApi = (dataFromApi) => {
        let numbersMap = {}
        for(let i=0; i<dataFromApi.length; i++){
            if (dataFromApi[i].telephoneNumber in numbersMap){
                // if numner already added in map then only change its value if true is added
                if(dataFromApi[i].isPortable === "Yes"){
                    numbersMap[dataFromApi[i].telephoneNumber] = "Yes"
                }
            } else {
                // add anything coming up
                numbersMap[dataFromApi[i].telephoneNumber] = dataFromApi[i].isPortable
            }
        }

        //console.log("numbersMap ===> ",numbersMap)
        let numbersArray = [];
        // numbers map has unique numbers and their values with yes being higher priority
        for (var key in numbersMap) {
            if (numbersMap.hasOwnProperty(key)) {
                const newObj = {
                    "telephoneNumber" : key,
                    "isPortable": numbersMap[key]
                }
                numbersArray.push(newObj);
            }
        }
        //console.log("numbersArray ===> ",numbersArray)
        return numbersArray;
    }

    createDataTable = (dataFromApi, showAllDetails) => {

        let columns =[];
        if (showAllDetails){

            columns =[
                {
                    label: 'Service Provider Name',
                    field: 'serviceProviderName',
                },
                {
                    label: 'Type',
                    field: 'type',
                },
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Telephone Number',
                    field: 'telephoneNumber',
                },
                // {
                //     label: 'Portable',
                //     field: 'isPortable',
                // },
                {
                    label: 'Portable',
                    field: 'icon',
                }
            ];

        } else {

            //console.log(dataFromApi)
            dataFromApi = this.filterDataFromApi(dataFromApi)
            columns =[
                {
                    label: 'Telephone Number',
                    field: 'telephoneNumber',
                },
                {
                    label: 'Portable',
                    field: 'icon',
                }
            ];
        }
        

        if(dataFromApi != null)
        {
            // dataFromApi = dataFromApi.map((row) => {
            //     if(row.isPortable === "Yes"){
            //         console.log("checked");
            //         row.isPortable = <FontAwesomeIcon 
            //         icon={faCheckCircle} className="icon-check"/>
            //     }
            //     else {
            //         row.isPortable = <FontAwesomeIcon 
            //         icon={faTimesCircle} className="icon-cross"/>
            //     }

            //     return({...row});
            // });
            let dataModified = null;
            dataModified = dataFromApi.map((row)=>{
                if(row.isPortable === "Yes"){
                    //console.log("In checked");
                    return ({...row, icon:  <FontAwesomeIcon 
                        icon={faCheckCircle} className="icon-check"/>})
                } else {
                    return ({...row, icon:  <FontAwesomeIcon 
                        icon={faTimesCircle} className="icon-cross"/>})
                }
            });
            this.setState({dataTable: {columns: columns, rows: dataModified}});
        }
        
    }

    addHandler = () => {

        

        let  numbers = this.state.numbers;
        let number = this.state.number.replaceAll(" ", "");
        if(numbers.includes("&"+number+"&")){
            toast.error("Number already added");
            return;
        }

        numbers = numbers.replaceAll(" ", "");
        if(numbers !== "") {
            numbers += ",";
        }
        numbers += "&"+number+"&";
        this.setState({numbers: numbers});
        this.setState({number: ""});

        const numbersList = numbers.split(",")
        let listData = null;
        listData = numbersList.map( number => {
            return(
                <div className="portability-word-container">
                    <span> {number.replaceAll("&", "")} </span>
                    <span onClick={this.deleteHandler} data-number={number} className="delete-icon-right">X</span>
                </div>
            );
        });
        this.setState({portabilityListNumbers: listData});
    }

    deleteHandler = (evt) => {
        
        const numberToDelete = evt.target.getAttribute("data-number");

        let numbers = this.state.numbers;
        numbers = numbers.replaceAll(numberToDelete, "");
        
        numbers = numbers.replaceAll(",,", ",");

        if(numbers[numbers.length - 1] === ","){
            numbers = numbers.substring(0, numbers.length -1)
        }

        if(numbers[0] === ","){
            numbers = numbers.substring(1, numbers.length)
        }

        this.setState({numbers: numbers});
        const numbersList = numbers.split(",")
        if(numbers.length === 0){
            this.setState({portabilityListNumbers: false});
            return
        }

        let listData = null;
        listData = numbersList.map( number => {
            return(
                <div className="portability-word-container">
                    <span> {number.replaceAll("&", "")} </span>
                    <span onClick={this.deleteHandler} data-number={number} className="delete-icon-right">X</span>
                </div>
            );
        });
        this.setState({portabilityListNumbers: listData});

    }

    showAllDetailsChangeHandler = (evt) =>{
        const action = evt.target.getAttribute("data-action");
        if(action==="enable"){
            this.setState({showAllDetails: true});
            this.createDataTable(this.state.portabilityData, true);
        } else{
            this.setState({showAllDetails: false});
            this.createDataTable(this.state.portabilityData, false);
        }
    }
    
    
    render() {

        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-12 heading">
                        <h2>Check Portability</h2>
                        <div className={"loader-box " + this.state.style} >
                            <div className="loader">
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div className="row">
                    <div className="col-md-2 col-0">
                    </div>
                    <div className = "col-md-8 col-12 PortableMainIndex">
                        <div className="row">
                            <div className="col-12 col-md-10">
                                <input type="text" placeHolder="Number"
                                    className="number-field form-control"
                                    onChange={e => this.setState({number: e.target.value})}
                                    value={this.state.number}>
                                </input>
                            </div>
                            <div className="col-md-2 col-12" style={{textAlign:"end"}}>
                                <button onClick={this.addHandler} type="button"  className="btn btn-rounded-custom btn-primary" >Add</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                                <p>HINT: To check list of numbers, you may enter numbers in the above field comma seperated with no spaces - I.E. 3348789203,6021234567,2121234567,5161234567</p>
                        </div>
                        <div className="portability-words-container">


                            {
                                this.state.portabilityListNumbers?
                                    this.state.portabilityListNumbers
                                :
                                    <h1> No Numbers Added </h1>
                            }   

                        </div>

                        <div className="row">

                            <div className="col-md-12" style={{textAlign: "center"}}>
                                <button onClick={this.searchPortability} type="button"  
                                    className="btn btn-rounded-custom btn-secondary" >Check</button>
                            </div>

                        </div>
                        
                        <div className="portability-table">
                            {
                                this.state.showAllDetails?
                                    <i className="fas fa-toggle-on data-table-icon mg-20" 
                                        onClick={this.showAllDetailsChangeHandler} 
                                        title="Hide extra inforamtion" 
                                        style={{color: "green", float: "right"}}
                                        data-action="disable"/>
                                :
                                    <i className="fas fa-toggle-off data-table-icon mg-20" 
                                        title="Show extra information"
                                        style={{float: "right"}}
                                        onClick={this.showAllDetailsChangeHandler}  
                                        data-action="enable"/>

                            }
                            <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                            pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                        </div>
                    </div>
                    <div className="col-md-2 col-0">
                    </div>
                </div>
                
                <ToastContainer />
            </Fragment>

        );

    }

}

export default CheckPortability;
