import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class UserDevices extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            profileIdSelected: null,
            style: 'custom-spinner',
            loading: true,
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
       // alert(this.props.location.state.domain+this.props.location.state.login)
        this.refreshData();
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            //user_id: JSON.parse(localStorage.getItem('creds')).id
            cloud_id:JSON.parse(localStorage.getItem('creds')).cloud_id,
            username:this.props.location.state.login
        };

        axios.post(`${process.env.REACT_APP_API_URL}getUserDevices`, payLoad, this.headers)
        .then(response=>{
            const extensions = "sip:" + this.props.location.state.login.split("@")[0]
            let data = response.data.filter((myData) => {
                if (myData.aor.includes(extensions+"m") || myData.aor.includes(extensions+"i") 
                    ||  myData.aor.includes(extensions+"e") ||  myData.aor.includes(extensions+"d")){
                    return true
                }
                return false
            })
      
            this.createDataTable(data);
            
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    DeviceSyncHandler = () => {
    this.setState({style: 'custom-spinner'});
    const payLoad = {
        //user_id: JSON.parse(localStorage.getItem('creds')).id
        cloud_id:JSON.parse(localStorage.getItem('creds')).cloud_id,
        username:this.props.location.state.login
    };

    axios.post(`${process.env.REACT_APP_API_URL}syncDevices`, payLoad, this.headers)
    .then(response=>{
        const extensions = "sip:" + this.props.location.state.login.split("@")[0]
        let data = [];
        if (response.data !== null){
            data = response.data.filter((myData) => {
                if (myData.aor.includes(extensions+"m") || myData.aor.includes(extensions+"i") 
                    ||  myData.aor.includes(extensions+"e") ||  myData.aor.includes(extensions+"d")){
                    return true
                }
                return false
            })
        }
        
        this.createDataTable(data);
        toast.success("Devices Synced Successfully!");
        this.setState({style: 'custom-spinner-hide'});
    })
    .catch(err=>{
        toast.error(err.message);
        this.setState({style: 'custom-spinner-hide'});
    });
}
//========================================================================================================================
    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Aor',
                field: 'aor',
            },
            {
                label: 'Login ID',
                field: 'sub_login',
            },
            {
                label: 'AOR',
                field: 'aor',
            },
            {
                label: 'User Agent',
                field: 'user_agent',
            },
            {
                label: 'Expiry',
                field: 'expires',
            },
            {
                label: 'Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataFromApi = dataFromApi.map((row) => {
                if(row.outboundproxyenabled){
                    row.outboundproxyenabled = "Yes"
                }
                else {
                    row.outboundproxyenabled = "No"
                }

                return({...row});
            });
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deleteHandler} data-aor={row.aor}  data-subscriber_name={row.subscriber_name}></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    deleteHandler = (event) =>{
        confirmAlert({
            title: 'Are you sure you want to remove this device?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            cloud_username : event.target.getAttribute("data-subscriber_name"),
                            aor : event.target.getAttribute("data-aor"),
                            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}DeleteUserDevice`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    editHandler = (event) =>{
        this.setState({profileIdSelected: event.target.getAttribute("data-id")})
        // will call the details compnent but with data id selected here as a prop

    }

    addNewHandler = (evt) => {
        evt.preventDefault();

        this.setState({newRecordClicked: true});

    }

    render() {

        let redirect = null;
        if(this.state.newRecordClicked){
            this.setState({newRecordClicked: false});
            redirect = <Redirect to = {{
                pathname: "/app_management",
                state: {selectedDomain1: this.props.location.state.domain}
            }} />;

        }
        
       

            if(this.state.backToIndex){
                redirect = <Redirect to = {{
                        pathname: "/index"
                }} />;
            }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="User Devices" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className = "MainIndex">
                    <div  style={{float:"right" , marginLeft: "10px" }}>
                        <button onClick={this.addNewHandler} type="button"  className="btn btn-rounded-custom btn-primary  check-email-btn" >Cancel </button>
                    </div>
                    <div  style={{float:"right"}}>
                        <button  type="button"  className="btn btn-rounded-custom btn-primary  table-btn " onClick={this.DeviceSyncHandler}>Device Sync </button>
                    </div>
                  
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default UserDevices;
