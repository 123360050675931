import React, {useEffect} from 'react';
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Card, CardBody } from 'reactstrap';

import '../../assets/scss/app-styles/shared-vm.scss'
import '../../assets/scss/app-styles/extensions.scss'

import { Typeahead } from 'react-bootstrap-typeahead';

const SharedVm = (props) => {

    return(
        <>

            <Modal isOpen={props.show} toggle={props.toggleHandler}>
                <ModalHeader toggle={props.toggleHandler}>{"Shared Voicemail For "+ props.sharedVmUser}</ModalHeader>
                <ModalBody>
                    <Card className="main-container">
                    <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <form className="row">
                                    <div className="col-md-8">
                                        <i className="fa fa-search type-ahead-search-icon"/>
                                        <Typeahead
                                            id="basic-typeahead"
                                            className="search-domains"
                                            labelKey="user"
                                            multiple={false}
                                            options={props.subscriberList}
                                            onChange={props.subscriberChangeHandler}
                                            placeholder="Choose a Subscriber..."
                                        />
                                        
                                    </div>
                                    <div className="col-md-4">
                                        <Button color="primary" onClick={props.addSubscriberHandler}>Add</Button>
                                    </div>
                                </form>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="row vm-container">
                        
                            {
                                props.sharedVmList.map((row, index) =>{

                                    return (
                                        <div className='col-md-3 user-container'>
                                            {row.vm_user}<i class="fa fa-times-circle"
                                            data-id={row.vm_user}
                                            onClick={props.deleteSharedVmHander}></i>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </ModalBody>
            </Modal>
        </>

    )};

export default SharedVm;
