import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader, Form,FormGroup,Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';


import '../../assets/scss/app-styles/domains.scss'
import { ResponsiveEmbed } from 'react-bootstrap';

class Domains extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedDomain: "",
            allDomainsCheckedList: "",
            style: 'custom-spinner',
            
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount () {
        this.setState({style: 'custom-spinner'});
        const payLoad = new FormData();
        payLoad.append('cloud_id', JSON.parse(localStorage.getItem('creds')).cloud_id);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=domain&action=read&utype=${'s'}&cloud_username=${JSON.parse(localStorage.getItem('creds')).superuser}&cloud_password=${"password"}`, payLoad)
        .then(response=>{

            this.setState({myDomains: response.data});
            this.getCheckedInfo(response.data)
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

    domainChangeHandler = (evt) => {
        let allDomainsCheckedList = this.state.allDomainsCheckedList;

        if(evt.target.checked){

            //add
            if(allDomainsCheckedList === ""){
                
                allDomainsCheckedList = "&" +evt.target.value +"&"
                //setAllUsersCheckedList(evt.target.value)
            }
            else{
                allDomainsCheckedList += ",&" + evt.target.value +"&";
            }
        }
        else{
            //remove
            const list =  (allDomainsCheckedList).split(",");
            allDomainsCheckedList = "";
            for(let i=0; i<list.length; i++){

                // if found the value ignore
                if(list[i] !== "&" + evt.target.value +"&"){

                    if(allDomainsCheckedList === ""){
                
                        allDomainsCheckedList = list[i];
                    }
                    else{
                        allDomainsCheckedList += "," + list[i];
                    }
                }
                
            }
        }

        this.setState({allDomainsCheckedList: allDomainsCheckedList});
        if(this.state.filteredListOfDomains){
            this.populateDomains(this.state.filteredListOfDomains, allDomainsCheckedList);
        } else {
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
        }
        

    }

    saveHandler = () => {
        const allDomainsList = this.state.allDomainsCheckedList.replaceAll("&","");
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            username: JSON.parse(localStorage.getItem('creds')).username,
            list: allDomainsList
        }
        axios.post(`${process.env.REACT_APP_API_URL}checkUncheckDomains`, payLoad, this.headers)
        .then(response=>{

            toast.success("Successfully Saved!");
            this.setState({searchValue: ""});
            this.populateDomains(this.state.myDomains, this.state.allDomainsCheckedList);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
        
    }

    getCheckedInfo = (domains) => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id : JSON.parse(localStorage.getItem('creds')).id
        }
        axios.post(`${process.env.REACT_APP_API_URL}getDomainsChecked`, payLoad, this.headers)
        .then(response=>{

            const data= response.data;
            let allDomainsCheckedList = "";
            for(let i=0; i< data.length; i++){

                if(allDomainsCheckedList === ""){
                    allDomainsCheckedList = "&" + data[i].domain +"&";
                }else{
                    allDomainsCheckedList += ",&"+data[i].domain +"&";
                }
            }
            this.setState({allDomainsCheckedList: allDomainsCheckedList});
            this.setState({originalAllDomainsCheckedList: data})
            this.populateDomains(domains, allDomainsCheckedList);
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            this.setState({allDomainsCheckedList: ""});
            this.populateDomains(domains, "");
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    populateDomains = (data, allDomainsCheckedList) => {
        let items = [];
        data.map((row, index)=>(
            items.push(
                <div className="form-group col-4">
                    <div key={index} className="domain-checkbox-container">
                        <div  className="checkbox checkbox-primary" >
                            <input id={"checkbox-primary-domain"+index} type="checkbox" value={row.domain}
                                onChange={this.domainChangeHandler} checked={allDomainsCheckedList.includes("&"+row.domain+"&")}/>
                            <label htmlFor={"checkbox-primary-domain"+index} style={{wordBreak: "break-all"}}>{row.domain}</label>
                        </div>
                    </div>
                </div>)
        ));

        this.setState({domainsList: items});
    }

    checkAll = (evt) => {
        if(evt.target.checked){
            let allDomainsCheckedList = this.state.allDomainsCheckedList;
            for(let i=0; i<(this.state.myDomains).length; i++){
                if(allDomainsCheckedList === ""){
                    allDomainsCheckedList = "&" + this.state.myDomains[i].domain +"&";
                }else{
                    allDomainsCheckedList += ",&"+this.state.myDomains[i].domain +"&";
                }
            }
            this.setState({allDomainsCheckedList:allDomainsCheckedList});
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
            this.setState({checkedAll: true});
        }
        else{
            const data = this.state.originalAllDomainsCheckedList;
            let allDomainsCheckedList = "";
            for(let i=0; i< data.length; i++){

                if(allDomainsCheckedList === ""){
                    allDomainsCheckedList = "&" + data[i].domain +"&";
                }else{
                    allDomainsCheckedList += ",&"+data[i].domain +"&";
                }
            }
            this.setState({allDomainsCheckedList:allDomainsCheckedList});
            this.populateDomains(this.state.myDomains, allDomainsCheckedList);
            this.setState({checkedAll: false});
        }
    }
    
    onChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    searchChangeHandler = (evt) => {
        this.setState({searchValue: evt.target.value});
        let domains = this.state.myDomains;
        domains = domains.filter((value, index) => {
            const domain = value.domain.toLowerCase();
            const searchResult =  evt.target.value.toLowerCase();
            return domain.includes(searchResult);
        });
        this.setState({filteredListOfDomains: domains});
        this.populateDomains(domains, this.state.allDomainsCheckedList);
    }


    render () {
        return (
            <Fragment>
                <Breadcrumb parent="App" title="Domains" />

                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {
                    this.state.domainsList?

                        <div className="container-fluid main-container">
                            <div className="col-md-2 col-sm-0"></div>
                            <Card className="col-md-8 col-sm-12">
                                <CardHeader>
                                    <h5>SMS/MMS Domain's Permission</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div className="file-content" style={{marginBottom: "20px"}}>
                                        <div className="form-inline">
                                            <div style={{width: "100%"}}>
                                                <i className="fa fa-search"></i>
                                                <Input
                                                    style={{width: "95%"}}
                                                    className="form-control-plaintext"
                                                    type="text"
                                                    value={this.state.searchValue}
                                                    onChange={this.searchChangeHandler}
                                                    placeholder="Search..." 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div  className="checkbox checkbox-primary" style={{marginLeft:"5px"}} >
                                        <input id={"checkbox-primary-all"} type="checkbox"
                                            onChange={this.checkAll} checked={this.state.checkedAll}/>
                                        <label htmlFor={"checkbox-primary-all"}>Check All</label>
                                    </div>

                                    <div className="row">
            
                                        {this.state.domainsList} 
                                        
                                    </div>
                                    
                                    <div className="btn-container">
                                        <button className="btn btn-primary" type="button" onClick={this.saveHandler}>Save</button>
                                    </div>
                                </CardBody>
                                
                            </Card>
                                
                            <div className="col-md-2 col-sm-0"></div>
                        </div>
                    :
                    null
                }
            </Fragment>
        );
    }
    
};

export default Domains;