import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Lightbox from "react-image-lightbox";
import {Card,CardBody,CardHeader,Media } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';
import ImageUploader from 'react-images-upload';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/gallery.scss'

class Gallery extends React.Component {

	constructor(props) {
		super(props);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            fetching_method: "remote_api",
            active: "true"
		},
		backToIndex: false,
        style: 'custom-spinner',
        photoIndex: {index:0,isOpen:false},
        imagesArray: [],
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	deleteHandler = (event, path) =>{
        event.preventDefault();
        confirmAlert({
            title: 'Are you sure you want to delete this image from gallery?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        this.setState({style: 'custom-spinner'});
                        const payLoad = {
                            path : path,
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteGalleryImage`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Image Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        };
        axios.post(`${process.env.REACT_APP_API_URL}readGalleryFiles`, payLoad, this.headers)
        .then(response => {
            let imagesArray = [];
            let imagesItems = response.data.map((image, index) => {
                imagesArray.push(process.env.REACT_APP_API_URL + image.path)
                return(
                    <figure className="col-xl-3 col-sm-6" key={"galleryImgcxdo-"+index}>
                        <FontAwesomeIcon icon={faCircleXmark} className="cross-button"
                            onClick={(evt) => this.deleteHandler(evt, image.path)} data-path={image.path}/>
                        <a href="#javascript"  data-size="1600x950">
                        <Media
                            src={`${process.env.REACT_APP_API_URL}${image.path}`}
                            alt="Gallery"
                            className="img-thumbnail"
                            onClick={() =>
                                this.setState({photoIndex: { ...this.state.photoIndex,index:index, isOpen:true}})
                            }
                        />
                        </a>
                        <div className="caption">
                            <h4>{image.caption}</h4>
                            <p>{image.width}x{image.height} <br/> {process.env.REACT_APP_API_URL + image.path}</p>
                        </div>
                    </figure>
                )
            });
            this.setState({imagesArray: imagesArray});
            this.setState({images: imagesItems});
            this.setState({style: 'custom-spinner-hide'});
        }).catch(err => {
            if(err.response){
                toast.error(err.response.data);
            } else {
                console.log(err)
            }
            
            this.setState({style: 'custom-spinner-hide'});
        });
    }

	componentDidMount() {
		if(JSON.parse(localStorage.getItem('creds')).cloud_id !== "CREXDEMO"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
		
        this.refreshData();

	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

    mediaClickHandler = (myIndex) => {
        this.setState({photoIndex: { ...this.state.photoIndex,index:myIndex, isOpen:true}})
    }

    onMovePrev = () => {
        const prev = (this.state.photoIndex.index + this.state.imagesArray.length - 1) % this.state.imagesArray.length
        this.setState({photoIndex: {...this.state.photoIndex,index:prev}})
    }
    onMoveNext = () => {
        const next = (this.state.photoIndex.index + 1) % this.state.imagesArray.length
        this.setState({photoIndex: {...this.state.photoIndex,index:next}})
    }

    onDropImage = (pictureFiles, pictureDataURLs) => {
        this.setState({ uploadedImage:  pictureFiles[0]});
    }

    saveHandler = () => {
        let payLoad = new FormData();

        if(!this.state.caption){
            toast.error("Caption is mandatory to upload an image");
            return
        }

        if (this.state.uploadedImage){
            payLoad.append('image', this.state.uploadedImage);
        } else {
            toast.error("Cant save without image");
            return
        }


        payLoad.append('user_id',  JSON.parse(localStorage.getItem('creds')).id);
        payLoad.append('caption',  this.state.caption);
	
		axios.post(`${process.env.REACT_APP_API_URL}uploadGalleryImage`, payLoad, this.headers)
		.then(response => {
			window.location.reload();
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            if (err.response){
                toast.error(err.response.data);
            } else{
                toast.error(err.message);
            }
            this.setState({style: 'custom-spinner-hide'});
		});
    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/subscribers',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="App" title="Gallery" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>

                <div className="container-fluid main-container">
                    
                    <Card className="col-md-8">
                        <CardHeader>
                            <h5>Upload Image</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div id="the-basics">
                                <div className="row form-group">
                                    <input type="text" className="form-control" name="caption" placeholder="Caption"
                                        onChange={ e => this.setState({caption: e.target.value})} 
                                        value={this.state.caption}/>
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label="Max file size: 5mb, accepted: jpg|jpeg|svg|gif|png"
                                        singleImage={true}
                                        buttonText="Upload"
                                        onChange={this.onDropImage}
                                        imgExtension={[".jpg",".jpeg", ".gif", ".png", ".svg"]}
                                        maxFileSize={( 5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                        fileSizeError=" file size is too big"
                                    />
                                </div>

                            </div>
                            {
                                this.state.uploadedImage? 
                                <div className="row form-group">
                                    <div className="col-md-12" style={{textAlign: "center"}}>
                                        <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success">
                                            SAVE
                                        </button>

                                    </div>
                                </div>
                                :null
                            }
                            
                        </CardBody>
                    </Card>
                    <Card className="col-md-4" style={{marginLeft: "10px"}}>
                        <CardHeader>
                            <h5>Notes</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                        
                            <ul style={{listStyleType: "disclosure-closed"}}>
                                <li>QR Code - 256x256</li>
                                <li>Custom Tab Background - 1440x2560</li>
                                <li>Signin Logo - 2059x566</li>
                                <li>Dashboard Logo - 567x106</li>
                            </ul>  
                            
                        </CardBody>
                    </Card>
                </div>

                <div className="container-fluid main-container">
                    <Card className="col-md-12">
                        <CardHeader>
                            <h5>Images</h5>
                        </CardHeader>
                        <CardBody className="my-gallery row gallery-with-description">
                            {this.state.images}
                        </CardBody>
                    </Card>
                </div>

                {this.state.photoIndex.isOpen && (
                <Lightbox
                    mainSrc={this.state.imagesArray[this.state.photoIndex.index]}
                    nextSrc={this.state.imagesArray[(this.state.photoIndex.index + 1) % this.state.imagesArray.length]}
                    prevSrc={this.state.imagesArray[(this.state.photoIndex.index + this.state.imagesArray.length - 1) % this.state.imagesArray.length]}
                    imageTitle={this.state.photoIndex.index + 1 + "/" + this.state.imagesArray.length}
                    onCloseRequest={() => this.setState({photoIndex: { ...this.state.imagesArray,isOpen:false}})}
                    onMovePrevRequest={this.onMovePrev}
                    onMoveNextRequest={this.onMoveNext}
                />
            )}
            </Fragment>
		);

	}
}

export default Gallery;
