import React, { useState, useEffect, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { RESETPASSWORD,NewPassword,RetypePassword,Reset} from "../constant";

const ResetPassword = ({ history }) => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [frontendUrl, setFrontendUrl] = useState("");
    const [signinLogo, setSigninLogo] = useState(localStorage.getItem("signin_logo"));


    useEffect(() => {
        setFrontendUrl(window.location.origin);
        const payLoad = {
            base_url: window.location.origin,
        };
        axios.post(`${process.env.REACT_APP_API_URL}getCompanyLogos`, payLoad)
        .then(response => {
            localStorage.setItem("signin_logo",response.data.signin_logo);
            localStorage.setItem("dashboard_logo",response.data.dashboard_logo);
            localStorage.setItem("custom_tab_background",response.data.custom_tab_background);
            setSigninLogo(response.data.signin_logo);
        }).catch(err => {
            if (err.response){
                console.log(err.response.data);
            } else{
                console.log(err.message);
            }
            
        });
    }, []);

    const resetPassword = (evt) => {
        evt.preventDefault();
        const url = new URL(window.location.href);
        const ref = url.searchParams.get("ref");
        if(!newPassword || !confirmPassword){
            toast.error("Please fill both fields")
            return;
        }
        if(newPassword.length < 6){
            toast.error("New Password should be atleast 6 characters long!")
            return;
        }
        if(newPassword !== confirmPassword){
            toast.error("Passwords doesnot match")
            return;
        }
        const payLoad = {
            ref: encodeURIComponent(ref),
            password: newPassword
        }
        axios.post(`${process.env.REACT_APP_API_URL}resetPassword`, payLoad)
        .then(response=>{
            toast.info("Password has been resetted successfully!");
        })
        .catch(err=>{
            toast.error(err.response.data)
        });
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                    <div className="text-center"><img width="300" 
                            src={signinLogo?process.env.REACT_APP_API_URL + localStorage.getItem("signin_logo"):null} alt="" /></div>
                        <div className="card mt-4 p-4">
                            <form className="theme-form">
                                <h5 className="f-16 mb-3 f-w-600">{RESETPASSWORD}</h5>
                                <div className="form-group">
                                    <label className="col-form-label">{NewPassword}</label>
                                    <input className="form-control" type="password"  value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label">{RetypePassword}</label>
                                    <input className="form-control" type="password" value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)} />
                                </div>
                                <div className="form-group form-row mb-0">
                                    <div className="col-md-2">
                                        <button className="btn btn-primary" onClick={resetPassword}>{Reset}</button>
                                    </div>
                                </div>
                            </form>
                            <p className="mb-2 text-muted reset-p"> Back To Login
                                <a href={`${frontendUrl}/login`} style={{fontWeight: "800", cursor: "pointer", color: "black"}}> Login</a>
                            </p>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(ResetPassword);